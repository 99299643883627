import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { FluxoCaixa } from '@/models/FluxoCaixa'
import Swal from 'sweetalert2'
import rules from '@/utils/formRules'
import { fluxoCaixaTransformer } from '@/utils/transformers'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const LOAD_ROLES = 'LOAD_ROLES'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'
export const CLOSE = 'CLOSE'
export const LOAD_CATEGORIA = 'LOAD_CATEGORIA'
export const LOAD_CONTA = 'LOAD_CONTA'
export const LOAD_ASSOCIADO = 'LOAD_ASSOCIADO'
export const LOAD_FORNECEDOR = 'LOAD_FORNECEDOR'
export const LOAD_FUNCIONARIO = 'LOAD_FUNCIONARIO'

const getDefaultState = () => {
  return {
    loading: true,
    isEditing: false,
    isDespesa: false,
    dialog: false,
    loadedCategoria: [],
    loadedConta: [],
    loadedAssociado: [],
    loadedFornecedor: [],
    loadedFuncionario: [],
    rules,
    route: '',
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE](state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE](state, payload) {
    Object.assign(state, payload)
  },
  [CLOSE](state) {
    state.dialog = false
    state.form = new FluxoCaixa()
  },
}

const actions = {
  async [LOAD_CATEGORIA]({ commit, state }) {
    await api.listEntidade('categoria').then(response => {
      let categoria
      categoria = response.filter(categ => categ.status === 1 && categ.classe === "S" && categ.tipo === (state.isDespesa ? 1 : 0))
      categoria.sort((A, B) => {
        const a = A.descricao.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')

        const b = B.descricao.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')
        return a < b ? -1 : a > b ? 1 : 0
      })
      commit(SET_STATE, { loadedCategoria: categoria })
    })
  },
  async [LOAD_CONTA]({ commit, state }) {
    await api.listEntidade('conta').then(conta => {
      conta.sort((A, B) => {
        const a = A.instituicao_financeira.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')

        const b = B.instituicao_financeira.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')
        return a < b ? -1 : a > b ? 1 : 0
      })
      commit(SET_STATE, { loadedConta: conta })
    })
  },
  async [LOAD_FORNECEDOR]({ commit, state }) {
    await api.listEntidade('fornecedor/mini').then(response => {
      let fornecedores = response.filter(fornec => fornec.status === 1)
      fornecedores = fornecedores.map(({ cpf, cnpj, razao_social, nome, ...fornecedor }) => ({
        ...fornecedor,
        cpf_cnpj: cpf || cnpj,
        nome_razao_social: nome || razao_social,
      }))
      fornecedores.sort((A, B) => {
        const a = A.nome_razao_social.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')

        const b = B.nome_razao_social.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')
        return a < b ? -1 : a > b ? 1 : 0
      })
      commit(SET_STATE, { loadedFornecedor: fornecedores })
    })
  },
  async [LOAD_ASSOCIADO]({ commit }) {
    await api.listEntidade('associado/mini').then(response => {
      let associado = response.filter(assoc => assoc.status === 1)
      associado = associado.map(({ cpf, cnpj, razao_social, nome, ...associado }) => ({
        ...associado,
        cpf_cnpj: cpf || cnpj,
        nome_razao_social: nome || razao_social,
      }))
      associado.sort((A, B) => {
        const a = A.nome_razao_social.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')

        const b = B.nome_razao_social.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')
        return a < b ? -1 : a > b ? 1 : 0
      })
      associado.forEach(element => {
        element.nome_razao_social = '(' + element.codigo + ')  ' + element.nome_razao_social
      })
      commit(SET_STATE, { loadedAssociado: associado })
    })
  },
  async [LOAD_FUNCIONARIO]({ commit, state }, id) {
    await api.listEntidade('funcionario').then(response => {
      let funcionario = response.map(({ cpf, nome, ...funcionario }) => ({
        ...funcionario,
        cpf_cnpj: cpf,
        nome_razao_social: nome,
      }))
      funcionario.sort((A, B) => {
        const a = A.nome_razao_social.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')

        const b = B.nome_razao_social.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')
        return a < b ? -1 : a > b ? 1 : 0
      })
      funcionario.forEach(element => {
        element.nome_razao_social = element.nome_razao_social
      })
      commit(SET_STATE, { loadedFuncionario: funcionario })
    })
  },
  async [BOOTSTRAP_PROFILE]({ dispatch, commit, state }, { id, type, route }) {
    commit(RESET_STATE)
    commit(SET_STATE, { loading: true, isEditing: false, dialog: true, route: route })
    dispatch('form/BOOTSTRAP_FORM', { model: new FluxoCaixa() }, { root: true })
    if (type === 'despesa') {
      commit(SET_STATE, { isDespesa: true })
    } else if (type === 'receita') {
      commit(SET_STATE, { isDespesa: false })
    }
    await dispatch(LOAD_CATEGORIA)
    await dispatch(LOAD_CONTA)
    await dispatch(LOAD_ASSOCIADO)
    await dispatch(LOAD_FORNECEDOR)
    await dispatch(LOAD_FUNCIONARIO)
    if (id) {
      commit(SET_STATE, { isEditing: true })
      api.getEntidade('fluxo_caixa', id).then(response => {
        response.data.forEach(element => {
          element.status_pagamento = element.status_pagamento.toString()
          element.categoria_id = element.categoria.id.toString()
          element.conta_id = element.conta.id.toString()
          element.pessoa_id = element.pessoa ? element.pessoa.id.toString() : element.pessoa
        })
        commit(SET_STATE, { loading: false })
        dispatch('form/BOOTSTRAP_FORM', { model: new FluxoCaixa(fluxoCaixaTransformer(response.data[0])) }, { root: true })
      })
    } else {
      commit(SET_STATE, { loading: false })
    }
  },
  async [SUBMIT]({ commit, dispatch, state, rootState }, { router, user }) {
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        const form = { ...rootState.form.data };
        (() => {
          return state.isEditing
            ? api.salvarEntidade(form, 'fluxo_caixa', form.id)
            : api.cadastrarEntidade(form, 'fluxo_caixa')
        })().then(
          data => {
            Swal.fire({
              title: state.isEditing
                ? 'Movimento atualizado com sucesso!'
                : 'Movimento cadastrado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            commit(SET_STATE, { dialog: false })
            if (state.route != '') {
              dispatch(state.route + '/BOOTSTRAP', null, { root: true })
            }
          },
          error => {
            Swal.fire({
              icon: 'error',
              title: 'Atenção',
              html: error.errors ? error.errors : error.message,
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
