export class Conta {
  constructor ({
    id,
    saldo,
    instituicao_financeira,
    agencia,
    conta,
    descricao,
    cor,
    somar,
  } = {
      id: '',
      instituicao_financeira: '',
      agencia: '',
      conta: '',
      descricao: '',
      cor: null,
      somar: false,
    }) {
      this.id = id
      this.saldo = saldo
      this.instituicao_financeira = instituicao_financeira
      this.agencia = agencia
      this.conta = conta
      this.descricao = descricao
      this.cor = cor
      this.somar = somar
  }
}

export function createConta (data) {
  return Object.freeze(new Conta(data))
}
