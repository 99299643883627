<template>
  <v-row justify="center">
    <v-dialog
      v-model="transferencia_dialog"
      content-class="lateral-dialog"
      hide-overlay
      persistent
      transition="slide-x-reverse-transition"
      origin="right"
    >
      <base-snack-bar
        v-model="snackbar.show"
        :message="snackbar.message"
      />
      <v-card class="lateral-card">
        <v-toolbar
          dark
          color="#eeaf34"
        >
          <v-btn
            icon
            dark
            @click="cancel"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-col
            cols="11.5"
          >
            <span
              v-if="loading"
              class="headline"
            >Aguarde carregando Contas...</span>
            <span
              v-else
              class="headline"
            >{{ isEditing ? 'Editar Transferência' : 'Adicionar Nova Transferência' }}</span>
          </v-col>
          <!-- <v-btn
            icon
            dark
            @click.stop="cancel"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click.stop="cancel"
            >
              Save
            </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-card-text
          outlined
        >
          <base-loading v-if="loading" />
          <div v-else>
            <v-form
              ref="form"
              dense
              lazy-validation
            >
              <v-row>
                <v-col>
                  <v-card
                    elevation="0"
                  >
                    <v-row class="px-0 py-0">
                      <v-col
                        cols="8"
                        md="6"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <base-vuetify-money
                          v-model="valor"
                          :options="money"
                          maxlength="14"
                          :rules="[rules.required]"
                          label="Valor*"
                          validate-on-blur
                          dense
                          outlined
                          hide-details
                          rounded
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <data-field
                          v-model="data_transferencia"
                          label="Data"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="8"
                        align-self="center"
                        class="py-2 mr-0 pr-1"
                      >
                        <v-autocomplete
                          v-model="conta_origem_id"
                          :items="loadedContas"
                          item-text="instituicao_financeira"
                          item-value="id"
                          dense
                          label="Conta Origem*"
                          clearable
                          :loading="!loadedContas"
                          :rules="[rules.minLength(1, '* Obrigatório'), rules.required]"
                          autocomplete="nope"
                          validate-on-blur
                          outlined
                          rounded
                          hide-details
                        >
                          <template
                            slot="item"
                            slot-scope="data"
                          >
                            <v-list-item-content
                              style="max-height: 100px"
                              class="overflow-y-auto"
                            >
                              <v-list-item-title>{{ data.item.instituicao_financeira }}</v-list-item-title>
                              <v-list-item-subtitle>{{ ( data.item.agencia + '  -  ' + data.item.conta ) }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        md="8"
                        align-self="center"
                        class="py-2 mr-0 pr-1"
                      >
                        <v-autocomplete
                          v-model="conta_destino_id"
                          :items="loadedContas"
                          item-text="instituicao_financeira"
                          item-value="id"
                          dense
                          label="Conta Destino*"
                          clearable
                          :loading="!loadedContas"
                          :rules="[rules.minLength(1, '* Obrigatório'), rules.required]"
                          autocomplete="nope"
                          validate-on-blur
                          outlined
                          rounded
                          hide-details
                        >
                          <template
                            slot="item"
                            slot-scope="data"
                          >
                            <v-list-item-content
                              style="max-height: 100px"
                              class="overflow-y-auto"
                            >
                              <v-list-item-title>{{ data.item.instituicao_financeira }}</v-list-item-title>
                              <v-list-item-subtitle>{{ ( data.item.agencia + '  -  ' + data.item.conta ) }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        align-self="center"
                        class="pl-2 pr-1 pb-2"
                      >
                        <v-textarea
                          v-model="observacao"
                          label="Observação*"
                          validate-on-blur
                          outlined
                          rounded
                          :rules="[rules.maxLength(255, 'Max. 255 caracteres'), rules.required]"
                          height="67"
                          no-resize
                          maxlength="255"
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions
          class="justify-end mt-0"
        >
          <v-btn
            class="text-none text-white"
            color="error"
            rounded
            @click="cancel"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            class="text-none text-white"
            color="success"
            depressed
            rounded
            @click="submit"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import { CLOSE_TRANSFERENCIA, SET_STATE, SUBMIT } from '@/store/modules/forms/transferencia'
  import { mapMutations, mapState, mapActions } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import Swal from 'sweetalert2'

  export default {
    name: 'FluxoCaixaTransferenciaDialog',
    // inheritAttrs: false,

    data () {
      return {
        rules,
        currencyFormatter,
        valid: false,
        snackbar: {
          message: '',
          show: false,
          color: 'error',
        },
        money: {
          locale: 'pt-BR',
          precision: 2,
        },
        escolheCategoria: null,
        escolheDescricao: null,
        escolheOrigem: null,
      }
    },
    computed: {
      ...mapState('form/transferencia', ['loading', 'transferencia_dialog', 'isEditing', 'loadedContas', 'loadedTrasnferencia']),
      ...mapFields('form', [
        'data.id',
        'data.valor',
        'data.data_transferencia',
        'data.conta_origem_id',
        'data.conta_destino_id',
        'data.observacao',
        'data.recorrencia',
      ]),
    },
    created () {
    },
    methods: {
      ...mapMutations('form/transferencia', [CLOSE_TRANSFERENCIA, SET_STATE]),
      ...mapActions('form/transferencia', [SUBMIT]),
      cancel () {
        this.CLOSE_TRANSFERENCIA()
      },
      submit () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }

        this.SUBMIT({ router: this.$router, user: this.$user })
      },
    },
  }
</script>
<style lang="scss">
  .lateral-dialog {
    margin: 0px;
    height: 100%;
    width: 360px;
    position: absolute;
      right: 0;
      margin: 0;
      min-height: 100%;
  }

  .lateral-card {
    height: 100%;
  }

  div:has(> div[class*='lateral-dialog']) {
    margin-top: 57px;
  }
</style>
