import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const BOOTSTRAP = 'BOOTSTRAP'
export const LOAD_CONTAS = 'LOAD_CONTAS'
export const NEW_TRANSFERENCIA = 'NEW_TRANSFERENCIA'
export const NEW_CONTA = 'NEW_CONTA'
export const NEW_RECEITA = 'NEW_RECEITA'
export const NEW_DESPESA = 'NEW_DESPESA'
export const UPDATE_CONTA = 'UPDATE_CONTA'
export const SHOW_EXTRATO = 'SHOW_EXTRATO'
export const RESET_STATE = 'RESET_STATE'
export const PERIODO = 'PERIODO'

const getDefaultState = () => {
  return {
    loading: true,
    data_i: 'from',
    contas: [],
    totalSaldoAtual: 0,
    totalSaldoPrevisto: 0,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE](state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE](state) {
    Object.assign(state, getDefaultState())
  },
  [PERIODO](state, { data_i }) {
    state.data_i = data_i
  },
}

const actions = {
  async [LOAD_CONTAS]({ commit, state }) {
    await api.listEntidade('conta' + '/dashboard/' + state.data_i).then(
      contas => {
        const totalSaldoAtual = contas.reduce(function (total, atual) {
          return total + Number(atual.saldo_atual)
        }, 0)

        const totalSaldoPrevisto = contas.reduce(function (total, atual) {
          return total + Number(atual.saldo_previsto)
        }, 0)

        commit(SET_STATE, { totalSaldoAtual })
        commit(SET_STATE, { totalSaldoPrevisto })
        commit(SET_STATE, { contas })
      },
    )
  },

  [NEW_TRANSFERENCIA]({ dispatch }) {
    dispatch('form/transferencia/BOOTSTRAP_PROFILE', { id: null }, { root: true })
  },
  [NEW_CONTA]({ dispatch }) {
    dispatch('form/conta/BOOTSTRAP_PROFILE', { id: null }, { root: true })
  },
  [UPDATE_CONTA]({ dispatch }, id) {
    dispatch('form/conta/BOOTSTRAP_PROFILE', { id: id }, { root: true })
  },
  [NEW_RECEITA]({ dispatch }) {
    dispatch('form/modalLateral/BOOTSTRAP_PROFILE', { id: null, type: 'receita', route: 'conta' }, { root: true })
  },
  [NEW_DESPESA]({ dispatch }) {
    dispatch('form/modalLateral/BOOTSTRAP_PROFILE', { id: null, type: 'despesa', route: 'conta' }, { root: true })
  },

  async [BOOTSTRAP]({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_CONTAS)
    commit(SET_STATE, { loading: false })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
