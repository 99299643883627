const router = function (permissions) {
  return ({
    path: 'fluxoCaixa',
    component: () => import('@/views/FluxoCaixa/FluxoCaixaModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/FluxoCaixa/FluxoCaixaList.vue'),
        meta: { permissions, title: 'Fluxo de Caixa' },
      },
    ],
  })
}

export default {
  router,
}
