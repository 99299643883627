export class Transferencia {
	constructor ({
		id,
		valor,
		data_transferencia,
		conta_origem_id,
		conta_destino_id,
		observacao,
		recorrencia,
	} = {
		id: '',
		valor: 0,
		data_transferencia: (new Date()).toISOString().slice(0, 10),
		conta_origem_id: '',
		conta_destino_id: '',
		observacao: '',
		recorrencia: '',
	}) {
		this.id = id
		this.valor = valor
		this.data_transferencia = data_transferencia
		this.conta_origem_id = conta_origem_id
		this.conta_destino_id = conta_destino_id
		this.observacao = observacao
		this.recorrencia = recorrencia
	}
}

export function createTransferencie (data) {
	return Object.freeze(new Transferencia(data))
}
