<template>
  <div class="content">
    <template>
      <div>
        <v-row
          class="pt-2 px-5 pb-3"
        >
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <data-field
              v-model="data_filtro"
              :label="'Mês de Referência'"
              :mes-ano="true"
              @set="handleDataFilter()"
            />
          </v-col>
          <v-spacer/>
          <v-col
            v-if="!loading"
            cols="12"
            sm="4"
            md="auto"
            lg="auto"
            xl="auto"
          >
            <v-row>
              <v-col>
                <v-menu
                  open-on-hover
                  bottom
                  offset-y
                  content-class="menu_fluxo"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="text-none text-white"
                      color="primary"
                      v-bind="attrs"
                      rounded
                      block
                      v-on="on"
                    >
                      <v-icon
                        dark
                        left
                      >
                        mdi-plus
                      </v-icon>
                      Cadastrar
                    </v-btn>
                  </template>
                  <v-list class="ma-0 pa-0">
                    <v-list-item
                      :key="1"
                      :class="{active: true}"
                      style="
                        background-color: #E1FFE0;
                        border-radius: 10px;
                        margin: 10px;
                        padding: 0px;
                      "
                      @click.stop="NEW_RECEITA"
                    >
                      <v-list-item-title
                        class="mx-2 px-0"
                        style="
                          color: green;
                          font-weight: bold;
                        "
                      >
                        <v-icon
                          color="white"
                          class="mx-1 br-icon left"
                          style="
                            background-color: green;
                            font-size: 16px
                          "
                        >
                          mdi-trending-up
                        </v-icon>
                        Nova Receita
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :key="2"
                      :class="{active: true}"
                      style="
                        background-color: #fef3f5;
                        border-radius: 10px;
                        margin: 10px;
                        padding: 0px;
                      "
                      @click.stop="NEW_DESPESA"
                    >
                      <v-list-item-title
                        class="mx-2 px-0"
                        style="
                          color: red;
                          font-weight: bold;
                        "
                      >
                        <v-icon
                          color="white"
                          class="mx-1 br-icon right"
                          style="
                            background-color: red;
                            font-size: 16px;
                            transform: scaleX(-1);
                          "
                        >
                          mdi-trending-down
                        </v-icon>
                        Nova Despesa
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <modal-lateral/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <base-loading v-if="loading" />
        <div v-else>
          <v-row
            cols="12"
            class="px-5"
          >
            <!-- Card 1 -->
            <v-col
              cols="12"
              sm="6"
              lg="3"
              xl="3"
              class="pt-0 pb-0"
            >
              <material-stats-card
                title="Saldo anterior"
                :value="'R$ ' + currencyFormatter(dashboard.cards.saldo)"
                icon="mdi-currency-usd"
                color="blue"
                elevation="3"
              />
            </v-col>
            <!-- Card 2 -->
            <v-col
              cols="12"
              sm="6"
              lg="3"
              xl="3"
              class="pt-0 pb-0"
            >
              <material-stats-card
                title="Receitas"
                :value="'R$ ' + currencyFormatter(dashboard.cards.receitas)"
                icon="mdi-trending-up"
                color="green"
                elevation="3"
              />
            </v-col>
            <!-- Card 3 -->
            <v-col
              cols="12"
              sm="6"
              lg="3"
              xl="3"
              class="pt-0 pb-0"
            >
              <material-stats-card
                title="Despesas"
                :value="'R$ ' + currencyFormatter(dashboard.cards.despesas)"
                icon="mdi-trending-down"
                color="red"
                elevation="3"
              />
            </v-col>
            <!-- Card 4 -->
            <v-col
              cols="12"
              sm="6"
              lg="3"
              xl="3"
              class="pt-0 pb-0"
            >
              <material-stats-card
                title="Balanço"
                :value="'R$ ' + currencyFormatter(dashboard.cards.balanco)"
                icon="mdi-scale-balance"
                color="#f4ab1d"
                elevation="3"
              />
            </v-col>
          </v-row>
          <v-row
            cols="12"
            class="px-5"
            style="margin-top: 0px !important;"
          >
            <!-- Card 1 -->
            <v-col>
              <h1>Contas à Pagar</h1>
              <v-card style="margin-top: 0px;">
                <crud-list
                  v-model="dashboard.a_pagar"
                  :headers="headers"
                  :sort-desc="[false]"
                  :slots="['item.data_vencimento', 'item.valor_pagamento']"
                  total-column
                  :showSearch="false"
                >
                  <template v-slot:[`item.data_vencimento`]="{ item }">
                    <span>{{ moment(item.data_vencimento).format('DD/MM/YYYY') }}</span>
                  </template>
                  <template v-slot:[`item.valor_pagamento`]="{ item }">
                    <span>R$ {{ currencyFormatter(item.valor_pagamento) }}</span>
                  </template>
                </crud-list>
              </v-card>
            </v-col>
            <!-- Card 2 -->
            <v-col>
              <h1>Contas à Receber</h1>
              <v-card style="margin-top: 0px;">
                <crud-list
                  v-model="dashboard.a_receber"
                  :headers="headers"
                  :sort-desc="[false]"
                  :slots="['item.data_vencimento', 'item.valor_pagamento']"
                  total-column
                  :showSearch="false"
                >
                <template v-slot:[`item.data_vencimento`]="{ item }">
                  <span>{{ moment(item.data_vencimento).format('DD/MM/YYYY') }}</span>
                </template>
                  <template v-slot:[`item.valor_pagamento`]="{ item }">
                    <span>R$ {{ currencyFormatter(item.valor_pagamento) }}</span>
                  </template>
                </crud-list>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            cols="12"
            class="px-5"
            style="margin-top: 0px !important;"
          >
            <!-- Card 1 -->
            <v-col
              cols="12"
              sm="12"
              lg="8"
              xl="8"
              class="graficoVariacao"
            >
              <v-row
                class="pa-3"
                justify="center"
                style="overflow-y: hidden;"
              >
                <material-chart-card
                  v-if="dashboard.acumulado"
                  :data="dashboard.acumulado.data"
                  :options="optionsBar"
                  type="Line"
                  color="primary"
                  title="Variação Anual Receitas x Despesas"
                  icon="mdi-chart-bell-curve"
                  sub-icon-left="mdi-clock-outline"
                  sub-icon-color-left="info"
                  sub-text-left="Últimos 12 meses"
                  elevation="4"
                  class="legendasFluxo"
                />
              </v-row>
            </v-col>
            <!-- Card 2 -->
            <v-col
              cols="12"
              sm="12"
              lg="4"
              xl="4"
              class="graficoDespesa"
            >
              <v-row
                class="pa-3"
                justify="center"
                style="overflow-y: hidden;"
              >
                <material-chart-card
                  v-if="dashboard.despesas"
                  :data="dashboard.despesas.data"
                  :options="optionsPie"
                  type="Pie"
                  color="primary"
                  title="Despesas por categoria"
                  icon="mdi-chart-bell-curve"
                  sub-icon-left="mdi-clock-outline"
                  sub-icon-color-left="info"
                  sub-text-left="No mês de referência"
                  elevation="4"
                />
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import MaterialStatsCard from '../../components/base/MaterialStatsCard.vue'
  import MaterialChartCard from '../../components/base/MaterialChartCard'
  import ModalLateral from '../../components/base/ModalLateral.vue'
  import FluxoCaixaStore, { BOOTSTRAP, NEW_RECEITA, NEW_DESPESA, EDITAR_RECEITA, EDITAR_DESPESA, PERIODO } from '@/store/modules/fluxoCaixa'
  import ModalLateralStore, { CLOSE } from '@/store/modules/forms/modalLateral'
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import { sortDateTime } from '../../utils/sorts'
  import Tooltip from 'chartist-plugin-tooltips-updated'
  import Legend from 'chartist-plugin-legend'

  export default {
    name: 'DashboardFluxoCaixa',
    components: {
      MaterialStatsCard,
      MaterialChartCard,
      ModalLateral,
    },
    data () {
      return {
        currencyFormatter,
        data_filtro: 'from',
        optionsPie: {
          donut: true,
          width: '95%',
          donutWidth: '30%',
          height: 185,
          labelDirection: 'explode',
          labelOffSet: -20,
          showLabel: true,
          plugins: [
            Legend(),
            Tooltip({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return meta + '<br />' + value },
            }),
          ],
        },
        optionsBar: {
          axisX: {
            offset: 30,
          },
          axisY: {
            onlyInteger: false,
            offset: 120,
            labelInterpolationFnc: function (value) {
              return 'R$ ' + currencyFormatter(value, 2)
            },
          },
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            tension: 0,
          }),
          height: 185,
          low: 0,
          showArea: false,
          plugins: [
            Legend(),
            Tooltip({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return meta + '<br />' + currencyFormatter(value, 2) },
            }),
          ],
          chartPadding: {
            top: 30,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, floatingfilter: false, width: '20' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Observação', value: 'observacao', width: 'auto', report: true },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Vencimento', value: 'data_vencimento', width: 'auto', sort: sortDateTime, report: true },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Categoria', value: 'categoria.descricao', width: 'auto', report: true },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor', value: 'valor_pagamento', width: 'auto', sum: 'Money', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
        ],
      }
    },
    computed: {
      ...mapState('fluxoCaixa', ['loading', 'dashboard']),
      ...mapState('form/modalLateral', ['dialog']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!this.$store.state.fluxoCaixa) { this.$store.registerModule('fluxoCaixa', FluxoCaixaStore) }
      if (!this.$store.state.form.modalLateral) { this.$store.registerModule(['form', 'modalLateral'], ModalLateralStore) }

      let data_i = new Date()

      data_i = new Date(data_i.getFullYear(), data_i.getMonth(), 1)
      data_i = data_i.toISOString().slice(0, 10)

      this.data_filtro = data_i

      this.PERIODO({ data_i })

      this.BOOTSTRAP()

      this.$store.watch(('dialog', (val) => {
        val || this.CLOSE()
      }))
    },
    methods: {
      ...mapActions('fluxoCaixa', [BOOTSTRAP, NEW_DESPESA, NEW_RECEITA, EDITAR_DESPESA, EDITAR_RECEITA]),
      ...mapMutations('form/modalLateral', [CLOSE]),
      ...mapMutations('fluxoCaixa', [PERIODO]),
      handleDataFilter () {
        const data_i = this.data_filtro.substring(0, 7) + '-01'
        this.PERIODO({ data_i })
        this.BOOTSTRAP()
      },
    },
  }
</script>

<style lang="scss">

  .v-data-table > .v-data-table__wrapper > table>tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 35px !important;
  }

  .menu_fluxo {
    border-radius: 16px !important;
    background-color: #ffffff;
  }
  .normal {
    background: #ffffff;
    border-color: #9c9c9c;
  }
  .active {
    background: #f1f1ff;
  }

  .graficoVariacao .ct-square {
    height: 290px;
  }

  .graficoVariacao .v-card--material-chart .v-card--material__heading {
    max-height: 185px;
  }

  .graficoDespesa .v-card--material-chart .v-card--material__heading {
    max-height: 185px;
  }

  .graficoDespesa .ct-square {
    height: 290px;
    min-width: 350px !important;
  }

  .graficoDespesa .ct-chart-donut {
    padding-left: 30px;
  }

  .graficoDespesa .v-card--material-chart .theme--dark.v-card {
    background-color: white !important
  }

  .graficoDespesa .ct-legend {
    z-index: 10;
    list-style: none;
    text-align: left;
    padding-top: 40px;
    display:contents;

    li {
      position: relative;
      cursor: pointer;
      display: block;
      z-index: 100;
      width: fit-content;
    }
  }

</style>
<style lang="sass">

.legendasFluxo

 .ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut
  stroke: #51be39

 .ct-legend .ct-series-0:before
    background-color: #51be39
    border-color: #51be39

 .ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut
  stroke: #f84c4c

 .ct-legend .ct-series-1:before
   background-color: #f84c4c
   border-color: #f84c4c
</style>
