<template>
  <v-dialog
    v-model="dialog"
    max-width="1392px"
    width="1392px"
    scrollable
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
    style="height: 1062px !important; max-height: 1062px !important;"
  >
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-col
          cols="11.5"
        >
          <span
            v-if="loadingFatura"
            class="headline"
          >Aguarde carregando Associados e Fornecedores...</span>
          <span
            v-else
            class="headline"
          >{{ formTitle }}</span>
        </v-col>
        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        outlined
      >
        <base-loading v-if="loadingFatura" />
        <div v-else>
          <v-form
            ref="form"
            dense
            lazy-validation
          >
            <v-row>
              <v-col>
                <v-card
                  elevation="0"
                >
                  <v-row class="px-0 py-0">
                    <v-col
                      cols="6"
                      md="2"
                      align-self="center"
                      class="px-2 py-2"
                    >
                      <data-field
                        v-model="data_referencia"
                        label="Referência*"
                        :mes-ano="true"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      md="2"
                      align-self="center"
                      class="px-2 py-2"
                    >
                      <data-field
                        v-model="data_lancamento"
                        label="Data Lançamento*"
                        hide-details
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      align-self="center"
                      class="py-2 mr-0 pr-1"
                    >
                      <v-autocomplete
                        v-model="fornecedor_id"
                        :items="loadedFornecedor"
                        item-text="nome_razao_social"
                        item-value="id"
                        dense
                        label="Fornecedor*"
                        clearable
                        :loading="!loadedFornecedor"
                        :rules="[rules.minLength(1, '* Obrigatório')]"
                        autocomplete="nope"
                        validate-on-blur
                        outlined
                        rounded
                        hide-details
                      >
                        <template
                          slot="item"
                          slot-scope="data"
                        >
                          <v-list-item-content
                            style="max-height: 100px"
                            class="overflow-y-auto"
                          >
                            <v-list-item-title>{{ data.item.nome_razao_social }}</v-list-item-title>
                            <v-list-item-subtitle>{{ ( data.item.cpf_cnpj + (data.item.nome_fantasia ? '  -  ' + data.item.nome_fantasia : '') ) }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <base-vuetify-money
                        v-model="total_faturado"
                        :options="money"
                        maxlength="14"
                        :rules="[rules.required]"
                        :disabled="fornecedor_id === '' || fornecedor_id === null"
                        label="Valor do Faturamento*"
                        validate-on-blur
                        dense
                        outlined
                        hide-details
                        rounded
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      align-self="center"
                      class="pl-2 pr-1 pb-2"
                    >
                      <v-textarea
                        v-model="observacao"
                        label="Observação"
                        validate-on-blur
                        outlined
                        rounded
                        :rules="[rules.maxLength(255, 'Max. 255 caracteres')]"
                        :disabled="fornecedor_id === '' || fornecedor_id === null"
                        height="67"
                        no-resize
                        maxlength="255"
                        hide-details
                      />
                    </v-col>
                    <v-card
                      outlined
                      elevation="0"
                      class="ml-2 mr-1"
                      width="100%"
                      style="border-radius: 20px"
                    >
                      <crud-list
                        v-model="lancamentos"
                        :headers="headersLanc"
                        :actions="actionsLanc"
                        :sort-desc="[false]"
                        :item-height="$vuetify.breakpoint.xl ? '290' : '140'"
                        show-expand
                        style="max-width: 1354px !important;"
                        :slots="['item.valor_relatorio', 'item.valor_devolucao', 'item.valor_xml', 'item.valor_diferenca']"
                        class="pt-2 elevation-0"
                        :export-pdf-xlsx="true"
                      >
                        <template v-slot:[`item.valor_relatorio`]="{ item }">
                          <span>
                            {{ `${currencyFormatter(parseFloat(item.valor_relatorio), 2)}` }}
                          </span>
                        </template>
                        <template v-slot:[`item.valor_devolucao`]="{ item }">
                          <span>
                            {{ `${currencyFormatter(parseFloat(item.valor_devolucao), 2)}` }}
                          </span>
                        </template>
                        <template v-slot:[`item.valor_xml`]="{ item }">
                          <span>
                            {{ `${currencyFormatter(parseFloat(item.valor_xml), 2)}` }}
                          </span>
                        </template>
                        <template v-slot:[`item.valor_diferenca`]="{ item }">
                          <span>
                            {{ `${currencyFormatter(parseFloat(item.valor_diferenca), 2)}` }}
                          </span>
                        </template>
                        <template v-slot:btnCadastro>
                          <v-col
                            cols="12"
                            md="4"
                            class="ml-0 mr-0 mb-0"
                          >
                            <v-autocomplete
                              v-model="escolheAssociados"
                              :items="associadosDisponiveis()"
                              class="mb-4"
                              item-text="nome_razao_social"
                              item-value="id"
                              return-object
                              label="Selecione o Associado para ser adicionado*"
                              no-data-text="Não há Associados disponíveis"
                              clearable
                              autocomplete="autocomplete_off_hack_xfr4!k"
                              validate-on-blur
                              outlined
                              rounded
                              :disabled="fornecedor_id === '' || fornecedor_id === null"
                              dense
                              hide-details
                            >
                              <template
                                slot="item"
                                slot-scope="data"
                              >
                                <v-list-item-content
                                  style="max-height: 100px"
                                  class="overflow-y-auto"
                                >
                                  <v-list-item-title>{{ data.item.nome_razao_social }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ ( data.item.cpf_cnpj + (data.item.nome_fantasia ? '  -  ' + data.item.nome_fantasia : '') ) }}</v-list-item-subtitle>
                                  <span
                                    v-if="data.item.disabled"
                                    color="red"
                                  ><b><i>Este Associado já está lançado</i></b></span>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                            <v-autocomplete
                              v-model="escolheEventos"
                              :items="eventosDisponiveis()"
                              item-text="descricao"
                              item-value="id"
                              return-object
                              dense
                              label="Selecione o Evento para o cálculo manual*"
                              no-data-text="Não há Eventos disponíveis"
                              clearable
                              autocomplete="autocomplete_off_hack_xfr4!k"
                              validate-on-blur
                              outlined
                              rounded
                              :disabled="desabilitaEvento"
                              hide-details
                            >
                              <template
                                slot="item"
                                slot-scope="data"
                              >
                                <v-list-item-content
                                  style="max-height: 100px"
                                  class="overflow-y-auto"
                                >
                                  <v-list-item-title>{{ data.item.codigo + '  -  ' + data.item.descricao }}</v-list-item-title>
                                  <span
                                    v-if="data.item.disabled"
                                    color="red"
                                  ><b><i>Este Evento já está lançado</i></b></span>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            md="8"
                            class="ml-0 mr-0 mb-0"
                          >
                            <v-textarea
                              v-model="observacaoAssociado"
                              label="Observação do Associado"
                              validate-on-blur
                              outlined
                              rounded
                              :disabled="fornecedor_id === '' || fornecedor_id === null"
                              hide-details
                              height="100"
                              no-resize
                              counter="255"
                              maxlength="255"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <base-vuetify-money
                              v-model="valor_rel"
                              :options="money"
                              maxlength="14"
                              label="Valor Faturado*"
                              :disabled="validaCampos()"
                              validate-on-blur
                              dense
                              outlined
                              rounded
                              hide-details
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <base-vuetify-money
                              v-model="valor_dev"
                              :options="money"
                              maxlength="14"
                              label="Valor Devolução"
                              :disabled="validaCampos()"
                              validate-on-blur
                              dense
                              outlined
                              rounded
                              hide-details
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <base-vuetify-money
                              v-model="valor_x"
                              :options="money"
                              maxlength="14"
                              :disabled="validaCampos()"
                              label="Valor XML*"
                              validate-on-blur
                              dense
                              outlined
                              rounded
                              hide-details
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="1"
                            class="text-center"
                          >
                            <v-btn
                              v-if="$vuetify.breakpoint.smAndUp"
                              color="success"
                              fab
                              title="Adiciona os Associado."
                              class="text-none text-white mb-2"
                              @click="adicionaAssociado()"
                            >
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                            <v-btn
                              v-else
                              color="success"
                              rounded
                              title="Adiciona os Associado."
                              class="text-none text-white mb-2"
                              @click="adicionaAssociado()"
                            >
                              <v-icon>mdi-download</v-icon>
                              Adicionar Associado
                            </v-btn>
                          </v-col>
                        </template>
                        <template v-slot:expanded-item="{ item }">
                          <td
                            v-if="item.observacao !== ''"
                            :colspan="headersLanc.length"
                          >
                            <v-simple-table
                              :height="$vuetify.breakpoint.xl ? '90' : '90'"
                              fixed-header
                              class="my-1"
                              style="max-width: 1300px !important"
                              dense
                            >
                              <tbody>
                                <tr>
                                  <td
                                    class="text-left"
                                    style="max-width: 1298px;white-space: pre-wrap;"
                                  >
                                    <span
                                      style="font-weight: bold;"
                                      class="mb-0 pb-0"
                                    >
                                      Observação do Associado:
                                    </span>
                                    <span class="mb-1">{{ item.observacao }}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </td>
                        </template>
                      </crud-list>
                    </v-card>
                    <v-col
                      cols="12"
                      md="3"
                      :class="$vuetify.breakpoint.mdAndDown ? 'pl-2 pr-0' : 'pl-2'"
                    >
                      <base-vuetify-money
                        v-model="totalRelatorio"
                        :options="money"
                        maxlength="14"
                        label="Valor Faturado"
                        :disabled="true"
                        validate-on-blur
                        dense
                        outlined
                        rounded
                        hide-details
                        class="centered-input"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      :class="$vuetify.breakpoint.mdAndDown ? 'pl-2 pr-0' : 'pl-2'"
                    >
                      <base-vuetify-money
                        v-model="totalDevolucao"
                        :options="money"
                        maxlength="14"
                        label="Valor da Devolução"
                        :disabled="true"
                        validate-on-blur
                        dense
                        outlined
                        rounded
                        hide-details
                        class="centered-input"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      :class="$vuetify.breakpoint.mdAndDown ? 'pl-2 pr-0' : 'pl-2'"
                    >
                      <base-vuetify-money
                        v-model="totalLiquido"
                        :options="money"
                        maxlength="14"
                        label="Valor Líquido"
                        :disabled="true"
                        validate-on-blur
                        dense
                        outlined
                        rounded
                        hide-details
                        class="centered-input"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      :class="$vuetify.breakpoint.mdAndDown ? 'pl-2 pr-0' : 'pl-2'"
                    >
                      <base-vuetify-money
                        v-model="totalXML"
                        :options="money"
                        maxlength="14"
                        label="Valor do XML"
                        :disabled="true"
                        validate-on-blur
                        dense
                        outlined
                        rounded
                        hide-details
                        class="centered-input"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      :class="$vuetify.breakpoint.mdAndDown ? 'pl-2 pr-0' : 'pl-2 pr-1'"
                    >
                      <base-vuetify-money
                        v-model="totalDiferenca"
                        :options="money"
                        maxlength="10"
                        :disabled="true"
                        label="Valor da Diferença"
                        validate-on-blur
                        dense
                        outlined
                        rounded
                        hide-details
                        class="centered-input"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions
        class="justify-end mt-0"
      >
        <v-btn
          class="text-none text-white"
          color="error"
          rounded
          @click="cancel"
        >
          <v-icon
            dark
            left
          >
            mdi-minus-circle
          </v-icon>
          Cancelar
        </v-btn>
        <v-btn
          class="text-none text-white"
          color="success"
          depressed
          rounded
          @click="submit"
        >
          <v-icon
            dark
            left
          >
            mdi-checkbox-marked-circle
          </v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import { SUBMIT, CLOSE, SET_STATE } from '@/store/modules/forms/lancamentoFatura'
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import Swal from 'sweetalert2'

  export default {
    name: 'LancamentoForm',
    data () {
      return {
        rules,
        currencyFormatter,
        valid: false,
        snackbar: { message: '', show: false, color: 'error' },
        money: {
          locale: 'pt-BR',
          precision: 2,
        },
        escolheAssociados: null,
        escolheEventos: null,
        valor_rel: 0,
        valor_dev: 0,
        valor_x: 0,
        observacaoAssociado: '',
        totalRelatorio: 0,
        totalDevolucao: 0,
        totalLiquido: 0,
        totalXML: 0,
        totalDiferenca: 0,
        headersLanc: [
          { text: 'Ações', value: 'actions', sortable: false, groupable: false, floatingfilter: false, width: '40px' },
          { align: 'right', sortable: true, groupable: false, floatingfilter: true, text: 'Código', value: 'associado.codigo', width: '20', report: true, styleReport: { halign: 'right' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Associado', value: 'associado.nome_razao_social', width: 'auto', report: true },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor Faturado', value: 'valor_relatorio', width: 'auto', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor da Devolução', value: 'valor_devolucao', width: 'auto', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor do XML', value: 'valor_xml', width: 'auto', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor da Diferença', value: 'valor_diferenca', width: 'auto', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Evento cálculo Manual', value: 'evento.descricao', width: 'auto', report: true, styleReport: { halign: 'right' } },
        ],
        actionsLanc: [
          {
            title: 'Excluir Associado',
            color: 'red darken-3',
            click: item => this.removerAssociado(item),
            icon: 'mdi-trash-can-outline',
          },
        ],
      }
    },
    computed: {
      ...mapState('form/lancamentoFatura', ['dialog', 'loadingFatura', 'isEditing', 'loadedFornecedor', 'loadedAssociado', 'loadedEvento']),
      ...mapFields('form', [
        'data.id',
        'data.data_referencia',
        'data.data_lancamento',
        'data.fornecedor_id',
        'data.observacao',
        'data.total_faturado',
        'data.lancamentos',
      ]),
      desabilitaEvento () {
        if (this.fornecedor_id === '' || this.fornecedor_id === null) {
          return true
        }

        if (this.eventosDisponiveis() === null || this.eventosDisponiveis().length <= 0) {
          return true
        }

        return false
      },
      formTitle () {
        return !this.isEditing ? 'Lançar Faturamento' : 'Editar Faturamento'
      },
      vlr_relatorio () {
        let total = 0
        this.$store.state?.form?.data?.lancamentos?.forEach(associado => { total = (total + parseFloat(associado.valor_relatorio)) })
        return total.toFixed(2)
      },
      vlr_devolucao () {
        let total = 0
        this.$store.state?.form?.data?.lancamentos?.forEach(associado => { total = (total + parseFloat(associado.valor_devolucao)) })
        return total.toFixed(2)
      },
      vlr_liquido () {
        let totalDev = 0
        let totalRel = 0
        this.$store.state?.form?.data?.lancamentos?.forEach(associado => { totalDev = (totalDev + parseFloat(associado.valor_devolucao)) })
        this.$store.state?.form?.data?.lancamentos?.forEach(associado => { totalRel = (totalRel + parseFloat(associado.valor_relatorio)) })
        return (totalRel - totalDev).toFixed(2)
      },
      vlr_xml () {
        let total = 0
        this.$store.state?.form?.data?.lancamentos?.forEach(associado => { total = (total + parseFloat(associado.valor_xml)) })
        return total.toFixed(2)
      },
      vlr_diferenca () {
        let total = 0
        this.$store.state?.form?.data?.lancamentos?.forEach(associado => { total = (total + parseFloat(associado.valor_diferenca)) })
        return total.toFixed(2)
      },
    },
    methods: {
      ...mapMutations('form/lancamentoFatura', [CLOSE, SET_STATE]),
      ...mapActions('form/lancamentoFatura', [SUBMIT]),

      cancel () {
        this.CLOSE()
      },
      atualiza_totais () {
        this.totalRelatorio = this.vlr_relatorio
        this.totalDevolucao = this.vlr_devolucao
        this.totalLiquido = this.vlr_liquido
        this.totalXML = this.vlr_xml
        this.totalDiferenca = this.vlr_diferenca
      },
      validaCampos () {
        if (this.$store.state?.form?.data?.fornecedor_id === '') {
          return true
        }

        if (this.escolheAssociados === null) {
          return true
        }

        return false
      },

      associadosDisponiveis () {
        const associado_disponiveis = this.loadedAssociado
        this.atualiza_totais()
        return associado_disponiveis
      },
      bloqueiaEventoLancamento () {
        if (this.eventosDisponiveis().length <= 0) {
          return false
        }

        const eventosFornecedor = this.loadedFornecedor.filter(fornec => fornec.id === this.fornecedor_id)

        if (this.eventosDisponiveis().length > 0 && this.eventosDisponiveis().length < eventosFornecedor[0]?.eventos.length) {
          return false
        }

        if ((this.escolheEventos === null || this.escolheEventos === '') && this.eventosDisponiveis().length === eventosFornecedor[0]?.eventos.length) {
          return true
        }

        return false
      },
      eventosDisponiveis () {
        const eventosFornecedor = this.loadedFornecedor.filter(fornec => fornec.id === this.fornecedor_id)
        let eventos = null
        if (eventosFornecedor !== undefined && eventosFornecedor.length > 0) {
          eventos = this.loadedEvento.filter(eventos => {
            if (eventosFornecedor[0]?.eventos.some(ob => ob.evento_id === eventos.id)) {
              return true
            } else {
              return false
            }
          })
          if (eventos.lenght <= 0) {
            eventos = null
          }
        }

        return eventos
      },
      adicionaAssociado () {
        if (this.escolheAssociados === null || this.escolheAssociados === '') {
          this.snackbar = {
            show: true,
            message: 'É necessário informar um Associado! Por favor, informe um Associado!',
            icon: 'success',
          }
          return null
        }

        if (this.bloqueiaEventoLancamento()) {
          this.snackbar = {
            show: true,
            message: 'É necessário informar um Evento para o lançamento! Por favor, informe um Evento Manual!',
            icon: 'success',
          }
          return null
        }

        if (this.valor_rel === '') {
          this.valor_rel = 0
        } else {
          this.valor_rel = parseFloat(this.valor_rel)
        }

        if (this.valor_x === '') {
          this.valor_x = 0
        } else {
          this.valor_x = parseFloat(this.valor_x)
        }

        if (this.valor_dev === '') {
          this.valor_dev = 0
        } else {
          this.valor_dev = parseFloat(this.valor_dev)
        }

        if (this.valor_rel <= 0 && this.valor_x <= 0 && this.valor_dev <= 0) {
          this.snackbar = {
            show: true,
            message: 'Não é permitido informar Associado sem um valor válido. Por favor, preencha-os campos!',
          }
          return null
        }

        const dadosGrid = this.$store.state.form.data.lancamentos.find(element => element.associado === this.escolheAssociados && element.evento === this.escolheEventos)

        if (dadosGrid) {
          dadosGrid.valor_relatorio += this.valor_rel
          dadosGrid.valor_devolucao += this.valor_dev
          dadosGrid.valor_xml += this.valor_x
          dadosGrid.valor_diferenca += this.valor_x > 0 ? (this.valor_rel - this.valor_x) : 0
        } else {
          this.$store.state.form.data.lancamentos.push({ associado: this.escolheAssociados, valor_relatorio: this.valor_rel, valor_devolucao: this.valor_dev, valor_xml: this.valor_x, valor_diferenca: this.valor_x > 0 ? (this.valor_rel - this.valor_x) : 0, observacao: this.observacaoAssociado, evento: this.escolheEventos })
        }

        this.escolheAssociados = {}
        this.escolheEventos = {}
        this.valor_rel = 0.00
        this.valor_x = 0.00
        this.valor_dev = 0.00
        this.observacaoAssociado = ''
      },
      removerAssociado (item) {
        const index = this.$store.state.form.data.lancamentos.indexOf(item)
        this.$store.state.form.data.lancamentos.splice(index, 1)
      },
      submit () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }

        if (this.$store.state.form.data.lancamentos.lenght < 1 || this.$store.state.form.data.lancamentos === undefined) {
          this.snackbar = {
            show: true,
            message: 'E necessário ter no mínimo 1 Associado com valores lançados. Por favor, lance um Associado!',
          }
          return null
        }

        if (parseFloat(this.$store.state?.form?.data?.total_faturado) !== parseFloat(this.totalLiquido)) {
          Swal.fire({
            title: 'Atenção...',
            text: 'O Valor do Faturamento informado diverge do valor cálculado! Deseja prosseguir assim mesmo?',
            icon: 'question',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonColor: '#109010',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Prosseguir',
            cancelButtonText: 'Cancelar',
            focusConfirm: false,
            preConfirm: () => {
              this.SUBMIT({ router: this.$router, user: this.$user })
            },
          })
        } else {
          this.SUBMIT({ router: this.$router, user: this.$user })
        }
      },
    },
  }
</script>
<style lang="css">
.text-field-table.v-text-field--outline input {
    text-align: right !important;
}
.centered-input input {
      text-align: right;
      font-weight: bold;
    }
</style>
