<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      content-class="lateral-dialog"
      hide-overlay
      persistent
      transition="slide-x-reverse-transition"
      origin="right"
    >
      <base-snack-bar
        v-model="snackbar.show"
        :message="snackbar.message"
      />
      <v-card class="lateral-card">
        <v-toolbar
          dark
          :color="isDespesa ? 'red' : 'green'"
        >
          <v-btn
            icon
            dark
            @click="cancel"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-col
            cols="11.5"
          >
            <span
              v-if="loading"
              class="headline"
            >Aguarde carregando as Categorias e Contas...</span>
            <span
              v-else
              class="headline"
            >{{ isEditing ? 'Editar' : 'Adicionar Nova' }} {{ isDespesa ? 'Despesa' : 'Receita' }}</span>
          </v-col>
        </v-toolbar>
        <v-card-text
          outlined
        >
          <base-loading v-if="loading" />
          <div v-else>
            <v-form
              ref="form"
              dense
              lazy-validation
            >
              <v-row>
                <v-col>
                  <v-card
                    elevation="0"
                  >
                    <v-row class="px-0 py-0">
                      <v-col
                        cols="12"
                        md="12"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <base-vuetify-money
                          v-model="valor_pagamento"
                          :options="money"
                          maxlength="14"
                          :rules="[rules.required]"
                          label="Valor do Faturamento*"
                          validate-on-blur
                          dense
                          outlined
                          hide-details
                          rounded
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <data-field
                          v-model="data_referencia"
                          :data-max="data_vencimento"
                          label="Referência*"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <data-field
                          v-model="data_vencimento"
                          :data-min="data_referencia"
                          label="Vencimento*"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <v-autocomplete
                          v-model="conta_id"
                          :items="loadedConta"
                          item-text="instituicao_financeira"
                          item-value="id"
                          dense
                          label="Conta*"
                          clearable
                          :loading="!loadedConta"
                          :rules="[rules.required]"
                          autocomplete="nope"
                          validate-on-blur
                          outlined
                          rounded
                          hide-details
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <v-autocomplete
                          v-model="categoria_id"
                          :items="loadedCategoria"
                          item-text="descricao"
                          item-value="id"
                          dense
                          label="Categoria*"
                          clearable
                          :loading="!loadedCategoria"
                          :rules="[rules.required]"
                          autocomplete="nope"
                          validate-on-blur
                          outlined
                          rounded
                          hide-details
                        />
                      </v-col>
                      <v-col
                        v-if="loadedCategoria.filter(el => el.id == categoria_id)[0]?.tipo_pessoa == 0"
                        cols="12"
                        md="12"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <v-autocomplete
                          v-model="pessoa_id"
                          :items="loadedAssociado"
                          class="mb-4"
                          item-text="nome_razao_social"
                          item-value="id"
                          :loading="!loadedAssociado"
                          :rules="[rules.required]"
                          label="Associado*"
                          no-data-text="Não há Associados disponíveis"
                          clearable
                          autocomplete="autocomplete_off_hack_xfr4!k"
                          validate-on-blur
                          outlined
                          rounded
                          dense
                          hide-details
                        >
                          <template
                            slot="item"
                            slot-scope="data"
                          >
                            <v-list-item-content
                              style="max-height: 100px"
                              class="overflow-y-auto"
                            >
                              <v-list-item-title>{{ data.item.nome_razao_social }}</v-list-item-title>
                              <v-list-item-subtitle>{{ ( data.item.cpf_cnpj + (data.item.nome_fantasia ? '  -  ' + data.item.nome_fantasia : '') ) }}</v-list-item-subtitle>
                              <span
                                v-if="data.item.disabled"
                                color="red"
                              ><b><i>Este Associado já está lançado</i></b></span>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        v-else-if="loadedCategoria.filter(el => el.id == categoria_id)[0]?.tipo_pessoa == 1"
                        cols="12"
                        md="12"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <v-autocomplete
                          v-model="pessoa_id"
                          :items="loadedFornecedor"
                          item-text="nome_razao_social"
                          item-value="id"
                          dense
                          label="Fornecedor*"
                          clearable
                          :loading="!loadedFornecedor"
                          :rules="[rules.required]"
                          autocomplete="nope"
                          validate-on-blur
                          outlined
                          rounded
                          hide-details
                        >
                          <template
                            slot="item"
                            slot-scope="data"
                          >
                            <v-list-item-content
                              style="max-height: 100px"
                              class="overflow-y-auto"
                            >
                              <v-list-item-title>{{ data.item.nome_razao_social }}</v-list-item-title>
                              <v-list-item-subtitle>{{ ( data.item.cpf_cnpj + data.item.nome_fantasia ? '  -  ' + data.item.nome_fantasia : '' ) }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        v-else-if="loadedCategoria.filter(el => el.id == categoria_id)[0]?.tipo_pessoa == 2"
                        cols="12"
                        md="12"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <v-autocomplete
                          v-model="pessoa_id"
                          :items="loadedFuncionario"
                          item-text="nome_razao_social"
                          item-value="id"
                          dense
                          label="Funcionário*"
                          clearable
                          :loading="!loadedFuncionario"
                          :rules="[rules.required]"
                          autocomplete="nope"
                          validate-on-blur
                          outlined
                          rounded
                          hide-details
                        >
                          <template
                            slot="item"
                            slot-scope="data"
                          >
                            <v-list-item-content
                              style="max-height: 100px"
                              class="overflow-y-auto"
                            >
                              <v-list-item-title>{{ data.item.nome_razao_social }}</v-list-item-title>
                              <v-list-item-subtitle>{{ ( data.item.cpf_cnpj ) }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <v-textarea
                          v-model="observacao"
                          label="Observação*"
                          validate-on-blur
                          outlined
                          rounded
                          :rules="[rules.maxLength(255, 'Max. 255 caracteres'), rules.required]"
                          height="67"
                          no-resize
                          maxlength="255"
                          hide-details
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <div style="width: 180px">
                          <v-radio-group
                            v-model="status_pagamento"
                            @change="limpaData"
                          >
                            <template v-slot:label>
                              <div><strong>Status Pagamento</strong></div>
                            </template>
                            <v-radio value="0">
                              <template v-slot:label>
                                <div><strong>{{ isDespesa ? 'À pagar' : 'À Receber' }}</strong></div>
                              </template>
                            </v-radio>
                            <v-radio value="1">
                              <template v-slot:label>
                                <div><strong>{{ isDespesa ? 'Pago' : 'Recebido' }}</strong></div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <data-field
                          v-if="status_pagamento === '1'"
                          v-model="data_pagamento"
                          :data-min="data_referencia"
                          label="Pagamento*"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions
          class="justify-end mt-0"
        >
          <v-btn
            class="text-none text-white"
            color="error"
            rounded
            @click="cancel"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            class="text-none text-white"
            color="success"
            depressed
            rounded
            @click="submit"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import { CLOSE, SET_STATE, SUBMIT } from '@/store/modules/forms/modalLateral'
  import { mapMutations, mapState, mapActions } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import moment from 'moment'

  export default {
    name: 'ModalLateralForm',
    inheritAttrs: false,

    data () {
      return {
        rules,
        currencyFormatter,
        valid: false,
        snackbar: {
          message: '',
          show: false,
          color: 'error',
        },
        money: {
          locale: 'pt-BR',
          precision: 2,
        },
      }
    },
    computed: {
      ...mapState('form/modalLateral', ['loading', 'dialog', 'isEditing', 'isDespesa', 'loadedCategoria', 'loadedConta', 'loadedAssociado', 'loadedFornecedor', 'loadedFuncionario']),
      ...mapFields('form', [
        'data.id',
        'data.valor_pagamento',
        'data.status_pagamento',
        'data.data_referencia',
        'data.data_vencimento',
        'data.data_pagamento',
        'data.categoria_id',
        'data.conta_id',
        'data.pessoa_id',
        'data.observacao',
      ]),
    },
    created () {
    },
    methods: {
      ...mapMutations('form/modalLateral', [CLOSE, SET_STATE]),
      ...mapActions('form/modalLateral', [SUBMIT]),
      cancel () {
        this.CLOSE()
      },
      submit () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }

        this.SUBMIT({ router: this.$router, user: this.$user })
      },
      limpaData () {
        if (this.status_pagamento === '0') {
          this.data_pagamento = null
        } else {
          this.data_pagamento = moment().format('YYYY-MM-DD')
        }
      },
    },
  }
</script>
<style lang="scss">
  .lateral-dialog {
    margin: 0px;
    height: 100%;
    width: 360px;
    position: absolute;
      right: 0;
      margin: 0;
      min-height: 100%;
  }

  .lateral-card {
    height: 100%;
  }

  div:has(> div[class*='lateral-dialog']) {
    margin-top: 57px;
  }
</style>
