<template>
  <div class="content">
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <crud-cpf-dialog
      :dialog="dialog"
      :titulo="'Funcionário'"
      :profile="'funcionario'"
      :store="'funcionarios'"
      :validar="'CPF'"
      @close-dialog="setFields"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <crud-cadastro
        :loading="loading"
      >
        <crud-card title="Dados Principais">
          <crud-pessoa-fisica-base
            ref="pf"
            class="pb-5"
            :optional="['rg']"
            :disabled="disabledFields"
            :input-outlined="true"
          />
          <crud-contato
            :telefones="telefones"
            :input-outlined="true"
          />
        </crud-card>

        <v-row
          class="pa-3"
          justify="center"
          justify-sm="end"
        >
          <v-btn
            class="mr-3"
            color="error"
            rounded
            @click="$router.push({ path: `/${$user.get().role}/funcionarios` })"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            :disabled="desabilitaBotao"
            rounded
            @click="submitForm"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            {{ isEditing ? 'Salvar' : 'Cadastrar' }}
          </v-btn>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import FuncionarioFormStore, { BOOTSTRAP_PROFILE, SUBMIT } from '@/store/modules/forms/funcionario'
  import { mapState, mapActions } from 'vuex'

  export default {
    data () {
      return {
        rules,
        valid: false,
        telefones: [
          {
            tipo: 'Comercial',
            format: 'com',
            title: 'Telefone 1',
            required: true,
          },
          { tipo: 'Comercial', format: 'com', title: 'Telefone 2' },
        ],
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('form/funcionario', ['loading', 'isEditing']),
      ...mapFields('form/funcionario', ['dialog']),
      disabledFields () {
        if (this.loaded || this.isEditing) {
          return ['email', 'cpf']
        }
        return ['cpf']
      },
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    created () {
      if (!this.$store.state.form.funcionario) { this.$store.registerModule(['form', 'funcionario'], FuncionarioFormStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/funcionario', [BOOTSTRAP_PROFILE, SUBMIT]),
      async setFields (data) {
        this.dialog = false

        if (data.value) {
          const { tipo, value } = data
          this.picked = tipo === 'cpf' ? 'fisica' : 'juridica'
          this.$store.commit('form/UPDATE_FORM_FIELD', { [tipo]: value })
          return
        }

        this.loadedData = true

        const id = data.id
        this.$router.push({
          path: `/${this.$user.get().role}/funcionarios/cadastro/`,
          query: { id },
        })
        this.BOOTSTRAP_PROFILE({ id })
      },
      submitForm () {
        this.$refs.pf.$v.$touch()
        if (!this.$refs.form.validate() || this.$refs.pf.$v.$invalid) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
    },
  }
</script>
