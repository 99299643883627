const router = function (permissions) {
  return ({
    path: 'fluxoCaixaContaExtrato',
    component: () => import('@/views/FluxoCaixaContaExtrato/FluxoCaixaContaExtratoModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/FluxoCaixaContaExtrato/FluxoCaixaContaExtratoList.vue'),
        meta: { permissions, title: 'Extrato' },
      },
    ],
  })
}

export default {
  router,
}
