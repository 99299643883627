<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <v-tabs
          v-model="tab"
          centered
          center-active
          fixed-tabs
          icons-and-text
          style="padding-top: 0px;  margin-top: 0px"
        >
          <v-tabs-slider />
          <v-tab>
            Receitas
            <v-icon>
              mdi-trending-up
            </v-icon>
          </v-tab>
          <v-tab>
            Despesas
            <v-icon>
              mdi-trending-down
            </v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item :key="0">
            <fluxo-caixa-movimentacoes-list-receita/>
          </v-tab-item>
          <v-tab-item :key="1">
            <fluxo-caixa-movimentacoes-list-despesa/>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </div>
</template>
<script>
  import FluxoCaixaMovimentacoesListReceita from './FluxoCaixaMovimentacoesListReceita'
  import FluxoCaixaMovimentacoesListDespesa from './FluxoCaixaMovimentacoesListDespesa'

  export default {
    components: {
      FluxoCaixaMovimentacoesListReceita,
      FluxoCaixaMovimentacoesListDespesa
    },
    data () {
      return {
        tab: 0,
      }
    },
  }
</script>
