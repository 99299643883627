import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const BOOTSTRAP = 'BOOTSTRAP'
export const LOAD_CONTAS = 'LOAD_CONTAS'
export const LOAD_BATCH = 'LOAD_BATCH'
export const LOAD_SALDO = 'LOAD_SALDO'
export const RESET_STATE = 'RESET_STATE'
export const PERIODO = 'PERIODO'

const getDefaultState = () => {
  return {
    loading: true,
    loadedContas: false,
    data: 'from',
    contas: [],
    contaExtrato: [],
    saldo: 0,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [PERIODO] (state, { data_i }) {
    state.data = data_i
  },
}

const actions = {
  async [LOAD_CONTAS] ({ commit, state }) {
    await api.listEntidade('conta').then(
      contas => {
        commit(SET_STATE, { contas })
      },
    )
  },
  async [LOAD_BATCH] ({ commit, state }, id) {
    await api.listEntidade('conta/extrato/' + id + '/' + state.data).then(
      contaExtrato => {
        commit(SET_STATE, { contaExtrato })
      },
    )
  },
  async [LOAD_SALDO] ({ commit, state }, id) {
    await api.listEntidade('conta/saldo/' + id).then(
      saldo => {
        commit(SET_STATE, saldo)
      },
    )
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }, id) {
    commit(SET_STATE, { loadedContas: false })
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_CONTAS)
    commit(SET_STATE, { loadedContas: true })

    await dispatch(LOAD_BATCH, id)
    commit(SET_STATE, { loading: false })
    await dispatch(LOAD_SALDO, id)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
