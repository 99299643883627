export class Categoria {
  constructor({
    id,
    codigo,
    descricao,
    tipo,
    tipo_pessoa,
    status,
  } = {
      id: '',
      codigo: '',
      descricao: '',
      tipo: '0',
      tipo_pessoa: '0',
      status: 0,
    }) {
      this.id = id
      this.codigo = codigo
      this.descricao = descricao
      this.tipo = tipo
      this.tipo_pessoa = tipo_pessoa
      this.status = status

  }
}

export function createCategoria (data) {
  return Object.freeze(new Categoria(data))
}
