<template>
  <div class="content">
    <template
      :style="
        $vuetify.breakpoint.xs
          ? 'margin: 4px'
          : 'margin: 0 18px 0 18px'
      "
      elevation="0"
    >
      <v-row
        class="pt-2 px-5"
        justify="space-between"
      >
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <data-field
            v-model="data_filtro"
            :label="'Mês de Referência'"
            :mes-ano="true"
            @set="handleDataFilter()"
          />
        </v-col>
        <v-col
          v-if="!loading"
          cols="12"
          sm="4"
          md="auto"
          lg="auto"
          xl="auto"
        >
          <v-row>
            <v-col>
              <v-menu
                open-on-hover
                bottom
                offset-y
                content-class="menu_conta"
              >
                <template
                  v-if="hasPermission('inserir_transferencia') || hasPermission('inserir_conta')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    class="text-none text-white"
                    color="primary"
                    v-bind="attrs"
                    rounded
                    block
                    v-on="on"
                  >
                    <v-icon
                      dark
                      left
                    >
                      mdi-plus
                    </v-icon>
                    Cadastrar
                  </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                  <v-list-item
                    v-if="hasPermission('inserir_fluxo_caixa')"
                    :key="1"
                    :class="{active: true}"
                    style="
                      background-color: #E1FFE0;
                      border-radius: 10px;
                      margin: 10px;
                      padding: 0px;
                    "
                    @click.stop="NEW_RECEITA"
                  >
                    <v-list-item-title
                      class="mx-2 px-0"
                      style="
                        color: green;
                        font-weight: bold;
                      "
                    >
                      <v-icon
                        color="white"
                        class="mx-1 br-icon left"
                        style="
                          background-color: green;
                          font-size: 16px
                        "
                      >
                        mdi-trending-up
                      </v-icon>
                      Nova Receita
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="hasPermission('inserir_fluxo_caixa')"
                    :key="2"
                    :class="{active: true}"
                    style="
                      background-color: #fef3f5;
                      border-radius: 10px;
                      margin: 10px;
                      padding: 0px;
                    "
                    @click.stop="NEW_DESPESA"
                  >
                    <v-list-item-title
                      class="mx-2 px-0"
                      style="
                        color: red;
                        font-weight: bold;
                      "
                    >
                      <v-icon
                        color="white"
                        class="mx-1 br-icon right"
                        style="
                          background-color: red;
                          font-size: 16px;
                          transform: scaleX(-1);
                        "
                      >
                        mdi-trending-down
                      </v-icon>
                      Nova Despesa
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="hasPermission('inserir_transferencia')"
                    :key="3"
                    :class="{active: true}"
                    style="
                        background-color: #f7ffe0;
                        border-radius: 10px;
                        margin: 10px;
                        padding: 0px;
                      "
                    @click.stop="NEW_TRANSFERENCIA"
                  >
                    <v-list-item-title
                      class="mx-2 px-0"
                      style="
                          color: #eeaf34;
                          font-weight: bold;
                        "
                    >
                      <v-icon
                        color="white"
                        class="mx-1 br-icon left"
                        style="
                            background-color: #eeaf34;
                            font-size: 16px
                          "
                      >
                        mdi-swap-horizontal
                      </v-icon>
                      Nova Transferência
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="hasPermission('inserir_conta')"
                    :key="4"
                    :class="{active: true}"
                    style="
                        background-color: #e0f9ff;
                        border-radius: 10px;
                        margin: 10px;
                        padding: 0px;
                      "
                    @click.stop="NEW_CONTA"
                  >
                    <v-list-item-title
                      class="mx-2 px-0"
                      style="
                          color: #557de3;
                          font-weight: bold;
                        "
                    >
                      <v-icon
                        color="white"
                        class="mx-1 br-icon left"
                        style="
                            background-color: #557de3;
                            font-size: 14px;
                            padding-left: 4px;
                            padding-right: 5px;
                          "
                      >
                        mdi-bank
                      </v-icon>
                      Nova Conta
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <modal-lateral />
              <fluxo-caixa-contas-dialog />
              <fluxo-caixa-transferencia-dialog />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <base-loading v-if="loading" />
      <v-row
        v-else
        class="px-5 pb-3 cols"
        justify="center"
      >
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="9"
        >
          <!-- Card 1 -->
          <v-data-iterator
            :items="contas"
            items-per-page.sync="3"
            page.sync="1"
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="12"
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <base-material-card
                    icon="mdi-bank"
                    class="v-card--material-stats"
                    :color="item.cor"
                  >
                    <v-row style="width: 90%;">
                      <v-col
                        class="pb-2"
                        cols="12"
                      >
                        <span style="padding-left: 10px; text-align: start; font-size: x-large; display: block;"> {{ item.instituicao_financeira }}</span>
                      </v-col>
                    </v-row>
                    <v-row style="width: 100%;">
                      <v-col
                        class="pb-2"
                        cols="6"
                        sm="6"
                        md="6"
                        lg="6"
                      >
                        <h3 class="display-2 font-weight-light text--primary" style="font-size: 1.2em !important;">
                          Saldo Atual
                        </h3>
                      </v-col>
                      <v-col
                        class="pb-2"
                        cols="6"
                        sm="6"
                        md="6"
                        lg="6"
                        style="justify-items: end;"
                      >
                        <h3 class="display-2 font-weight-light text--primary" style="font-size: 1.2em !important;">
                          R$ {{ currencyFormatter(item.saldo_atual) }}
                        </h3>
                      </v-col>
                    </v-row>
                    <v-row style="width: 100%;">
                      <v-col
                        class="pb-2"
                        cols="6"
                        sm="6"
                        md="6"
                        lg="6"
                      >
                        <h3 class="display-2 font-weight-light text--primary" style="font-size: 1.2em !important;">
                          Saldo Previsto
                        </h3>
                      </v-col>
                      <v-col
                        class="pb-2"
                        cols="6"
                        sm="6"
                        md="6"
                        lg="6"
                        style="justify-items: end;"
                      >
                        <!-- <h3 class="display-2 font-weight-light text--primary">
                            R$ 162.000,<small>00</small>
                          </h3> -->
                        <h3 class="display-2 font-weight-light text--primary" style="font-size: 1.2em !important;">
                          R$ {{ currencyFormatter(item.saldo_previsto) }}
                        </h3>
                      </v-col>
                    </v-row>
                    <v-col
                      cols="12"
                      class="px-0 py-2"
                    >
                      <v-divider />
                    </v-col>

                    <v-row>
                      <v-col
                        v-if="hasPermission('list_extrato_conta')"
                        class="pa-2 pl-1 pr-1 text-center"
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                      >
                        <v-btn
                          class="text-none text-white"
                          color="grey"
                          rounded
                          block
                          small
                          fab
                          style="color: white;"
                          @click="$router.push({ path: 'fluxoCaixaContaExtrato', query: {id: item.id} })"
                        >
                          <v-icon
                            dark
                            left
                          >
                            mdi-eye-outline
                          </v-icon>
                          Extrato
                        </v-btn>
                      </v-col>
                      <v-col
                        v-if="hasPermission('editar_conta')"
                        class="pa-2 pl-1 pr-1 text-center"
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                      >
                        <v-btn
                          class="text-none text-white"
                          color="blue"
                          rounded
                          block
                          small
                          fab
                          style="color: white;"
                          @click.stop="UPDATE_CONTA(item.id)"
                        >
                          <v-icon
                            dark
                            left
                          >
                            mdi-pencil
                          </v-icon>
                          Editar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </base-material-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="3"
        >
          <!-- Card 1 -->
          <v-col
            cols="12"
          >
            <material-stats-card
              title="Total saldo atual"
              :value="getSaldoAtual()"
              icon="mdi-currency-usd"
              color="blue"
              elevation="3"
            />
          </v-col>
          <!-- Card 2 -->
          <v-col
            cols="12"
          >
            <material-stats-card
              title="Total saldo previsto"
              :value="getSaldoPrevisto()"
              icon="mdi-trending-up"
              color="green"
              elevation="3"
            />
          </v-col>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>
  import store from '@/store'
  import MaterialStatsCard from '../../components/base/MaterialStatsCard.vue'
  import ModalLateral from '../../components/base/ModalLateral.vue'
  import FluxoCaixaContasDialog from './FluxoCaixaContasDialog.vue'
  import FluxoCaixaTransferenciaDialog from './FluxoCaixaTransferenciaDialog.vue'
  import ContaStore, { BOOTSTRAP, NEW_TRANSFERENCIA, NEW_CONTA, UPDATE_CONTA, NEW_RECEITA, NEW_DESPESA, SHOW_EXTRATO, PERIODO } from '@/store/modules/conta'
  import ModalLateralStore, { CLOSE } from '@/store/modules/forms/modalLateral'
  import ContasStore, { CLOSE_CONTA } from '@/store/modules/forms/conta'
  import TransferenciasStore, { CLOSE_TRANSFERENCIA } from '@/store/modules/forms/transferencia'
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    name: 'FluxoCaixaConta',
    components: {
      MaterialStatsCard,
      ModalLateral,
      FluxoCaixaContasDialog,
      FluxoCaixaTransferenciaDialog,
    },
    props: {
      title: {
        type: String,
        default: () => '',
      },
    },
    data () {
      return {
        currencyFormatter,
        data_filtro: 'from',
      }
    },
    computed: {
      ...mapState('conta', ['loading', 'contas', 'totalSaldoAtual', 'totalSaldoPrevisto']),
      ...mapState('form/modalLateral', ['dialog']),
      ...mapState('form/conta', ['conta_dialog']),
      ...mapState('form/transferencia', ['transferencia_dialog']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!store.getters.hasPermission('list_conta')) {
        this.$router.push({
          path: '/controleacesso/sempermissao',
        })
      }

      if (!this.$store.state.form.modalLateral) { this.$store.registerModule(['form', 'modalLateral'], ModalLateralStore) }
      if (!this.$store.state.conta) { this.$store.registerModule('conta', ContaStore) }
      if (!this.$store.state.form.conta) { this.$store.registerModule(['form', 'conta'], ContasStore) }
      if (!this.$store.state.form.transferencia) { this.$store.registerModule(['form', 'transferencia'], TransferenciasStore) }

      let data_i = new Date()

      data_i = new Date(data_i.getFullYear(), data_i.getMonth(), 1)
      data_i = data_i.toISOString().slice(0, 10)

      this.data_filtro = data_i

      this.PERIODO({ data_i })
      this.BOOTSTRAP()

      this.$store.watch(('dialog', (val) => {
        val || this.CLOSE()
      }))

      this.$store.watch(('conta_dialog', (val) => {
        val || this.CLOSE_CONTA()
      }))

      this.$store.watch(('transferencia_dialog', (val) => {
        val || this.CLOSE_TRASNFERENCIA()
      }))
    },
    methods: {
      ...mapActions('conta', [BOOTSTRAP, NEW_TRANSFERENCIA, NEW_CONTA, NEW_RECEITA, NEW_DESPESA, UPDATE_CONTA, SHOW_EXTRATO]),
      ...mapMutations('form/modalLateral', [CLOSE]),
      ...mapMutations('form/conta', [CLOSE_CONTA]),
      ...mapMutations('conta', [PERIODO]),
      ...mapMutations('form/transferencia', [CLOSE_TRANSFERENCIA]),
      handleDataFilter () {
        const data_i = this.data_filtro.substring(0, 7) + '-01'
        this.PERIODO({ data_i })
        this.BOOTSTRAP()
      },
      getSaldoAtual () {
        return 'R$ ' + currencyFormatter(this.totalSaldoAtual)
      },
      getSaldoPrevisto () {
        return 'R$ ' + currencyFormatter(this.totalSaldoPrevisto)
      },
    },
  }
</script>

<style lang="scss" scoped>

  .v-data-table > .v-data-table__wrapper > table>tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 35px !important;
  }

  .menu_conta {
    border-radius: 16px !important;
    background: #ffffff;
  }
  .normal {
    background: #ffffff;
    border-color: #9c9c9c;
  }
  .active {
    background: #f1f1ff;
  }

</style>
