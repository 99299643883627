const router = function (permissions) {
  return ({
    path: 'fluxoCaixaContas',
    component: () => import('@/views/FluxoCaixaContas/FluxoCaixaContasModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/FluxoCaixaContas/FluxoCaixaContasList.vue'),
        meta: { permissions, title: 'Contas Bancárias' },
      },
    ],
  })
}

export default {
  router,
}
