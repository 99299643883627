<template>
  <base-material-card
    :icon="icon"
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:after-heading>
      <div class="ml-auto text-right">
        <v-row>
          <v-col
            class="body-3 font-weight-bold"
            align-self="end"
            cols="12"
          >
          {{ title }}
        </v-col>
        </v-row>
        <v-row>
          <v-col
            align-self="end"
            cols="12"
          >
            <h3 class="display-2 font-weight-light text--primary">
              {{ value }} <small>{{ smallValue }}</small>
            </h3>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-if="subIcon || subText" v-slot:actions>
      <v-row
        v-if="subIcon || subText"
      >
        <v-col
          cols="11"
          class="pb-2"
        >
          <v-icon
            :color="subIconColor"
            size="16"
            class="ml-1  mr-1"
          >
            {{ subIcon }}
          </v-icon>
          <span
            :class="subTextColor"
            class="caption grey--text font-weight-light"
            v-text="subText"
          />
        </v-col>
        <v-col
          v-if="action"
          class="px-0 pb-0"
          cols="1"
        >
          <v-icon
            color="primary"
            size="16"
            title="Visualizar os itens"
            @click="action"
          >
            mdi-file-search-outline
          </v-icon>
        </v-col>
      </v-row>
      <div
        v-if="help && !action"
        style="position: absolute; right: 5px; bottom: 5px;"
      >
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="info"
              v-bind="attrs"
              small
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>{{ help }}</span>
        </v-tooltip>
      </div>
    </template>
  </base-material-card>
</template>

<script>
  import Card from './Card'

  export default {
    name: 'MaterialStatsCard',

    inheritAttrs: false,

    props: {
      ...Card.props,
      icon: {
        type: String,
        required: true,
      },
      subIcon: {
        type: String,
        default: undefined,
      },
      subIconColor: {
        type: String,
        default: undefined,
      },
      subTextColor: {
        type: String,
        default: undefined,
      },
      subText: {
        type: String,
        default: undefined,
      },
      title: {
        type: String,
        default: undefined,
      },
      value: {
        type: String,
        default: undefined,
      },
      smallValue: {
        type: String,
        default: undefined,
      },
      action: {
        type: Function,
        default: undefined,
      },
      help: {
        type: String,
        default: undefined,
      },
    },
  }
</script>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

  .v-card__actions
    flex: 1 0 100%
</style>
