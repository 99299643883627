<template>
  <div class="content">
    <template>
      <!-- Cabeçalho -->
      <v-row class="pt-2 px-5 pb-0">
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="2"
          xl="2"
        >
          <data-field
            v-model="data_filtro"
            :label="'Mês de Referência'"
            :mes-ano="true"
            @set="handleDataFilter()"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="2"
          xl="2"
        >
          <v-autocomplete
            v-model="conta_id"
            :items="contas"
            item-text="instituicao_financeira"
            item-value="id"
            dense
            label="Conta Origem*"
            :loading="!loadedContas"
            autocomplete="nope"
            validate-on-blur
            outlined
            rounded
            hide-details
            @change="changeConta"
          >
            <template
              slot="item"
              slot-scope="data"
            >
              <v-list-item-content
                style="max-height: 100px"
                class="overflow-y-auto"
              >
                <v-list-item-title>{{ data.item.instituicao_financeira }}</v-list-item-title>
                <v-list-item-subtitle>{{ ( data.item.agencia + '  -  ' + data.item.conta ) }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="pt-2 px-5 pb-3">
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
        >
          <!-- <span style="text-align: start; font-size: x-large; color: #666">Lançamentos</span> -->
          <v-card>
            <base-loading v-if="loading" />
            <div v-if="!loading">
              <crud-list
                v-model="contaExtrato"
                :headers="headers"
                :actions="actions"
                :permission="'list_extrato_conta'"
                :sort-desc="[false]"
                :slots="['item.tipo', 'item.data', 'item.valor']"
                total-column
              >
                <template v-slot:btnCadastro>
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-menu
                          open-on-hover
                          bottom
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="text-none text-white"
                              color="primary"
                              v-bind="attrs"
                              rounded
                              block
                              v-on="on"
                            >
                              <v-icon
                                dark
                                left
                              >
                                mdi-file-move
                              </v-icon>
                              Exportar
                            </v-btn>
                          </template>
                          <v-list class="ma-0 pa-0">
                            <v-list-item
                              :key="1"
                              :class="{active: true}"
                              @click="exportarExcel"
                            >
                              <v-list-item-title
                                class="mx-2 px-0"
                              >
                                <v-icon
                                  color="green"
                                  class="mx-2 px-0"
                                >
                                  mdi-file-excel
                                </v-icon>
                                Exportar XLSX
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              :key="2"
                              :class="{active: true}"
                              @click="exportarPdf"
                            >
                              <v-list-item-title class="mx-2 px-0">
                                <v-icon
                                  color="red"
                                  class="mx-2 px-0"
                                >
                                  mdi-file-pdf
                                </v-icon>
                                Exportar PDF
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
                <template v-slot:[`item.tipo`]="{ item }">
                  <v-chip
                    :color="((item.tipo == 'Transferência') ? 'orange' : (item.tipo == 'Depósito' ? 'green' : (item.tipo == 'Saque' ? 'red' : 'blue') ) )"
                    dark
                    outlined
                    dense
                  >
                    <span>{{ item.tipo }}</span>
                  </v-chip>
                </template>
                <template v-slot:[`item.data`]="{ item }">
                  <span>{{ moment( item.data ).format( "DD/MM/YYYY") }}</span>
                </template>
                <template v-slot:[`item.valor`]="{ item }">
                  <span
                    v-if="item.valor >= 0"
                    style="color: green;"
                  >R$ {{ currencyFormatter(item.valor >= 0 ? item.valor : item.valor*-1) }}</span>
                  <span
                    v-else
                    style="color: red;"
                  >R$ {{ currencyFormatter(item.valor >= 0 ? item.valor : item.valor*-1) }}</span>
                </template>
              </crud-list>
              <!-- <extrato-dialog :dados-dialog="dadosItem" /> -->
            </div>
          </v-card>
        </v-col>
        <v-col style="margin-right: 1%;">
          <!-- Card 1 -->
          <v-row>
            <v-col>
              <material-stats-card
                title="Caixa atual"
                :value="'R$ ' + currencyFormatter(saldo)"
                icon="mdi-currency-usd"
                color="blue"
                elevation="3"
              />
            </v-col>
          </v-row>

          <!-- Card 2 -->
          <v-row style="margin-top: -36px;">
            <v-col>
              <v-card
                v-if="displayDetalhe"
                class="pt-2 px-5 pb-3 cols"
                style="min-height: 500px; border-radius: 8px;"
              >
                <v-row>
                  <v-col>
                    <span style="text-align: start; font-size: x-large; font-weight: bold; color: #006aff;">{{ detalheExtrato.tipo }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h3 style="text-align: start; font-size: small; color: #999;">
                      Valor:
                    </h3>
                    <h3 style="text-align: start; font-size: x-large; color: #999;">
                      R$ {{ currencyFormatter(detalheExtrato.valor || 0) }}
                    </h3>
                  </v-col>
                  <v-col />
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="px-0 py-2"
                  >
                    <v-divider />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span style="text-align: start; font-size: large; font-weight: bold; color: #006aff;">Sobre a transação</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span style="text-align: end; font-size: small; font-weight: bold; color: #999; display: block;">{{ moment( detalheExtrato.data ).format( "DD/MM/YYYY") }}</span>
                  </v-col>
                </v-row>
                <v-row v-if="detalheExtrato.origem">
                  <v-col>
                    <span style="text-align: start; font-size: small; font-weight: bold; color: #999; display: block;">Conta Origem:</span>
                  </v-col>
                  <v-col>
                    <span style="text-align: end; font-size: small; color: #999; display: block;">{{ detalheExtrato.origem }}</span>
                  </v-col>
                </v-row>
                <v-row v-if="detalheExtrato.destino">
                  <v-col>
                    <span style="text-align: start; font-size: small; font-weight: bold; color: #999; display: block;">Conta Destino:</span>
                  </v-col>
                  <v-col>
                    <span style="text-align: end; font-size: small; color: #999; display: block;">{{ detalheExtrato.destino }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span style="text-align: start; font-size: small; font-weight: bold; color: #999; display: block;">Descrição:</span>
                    <span style="text-align: start; font-size: small; color: #999; display: block;">{{ detalheExtrato.descricao }}</span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>
  import store from '@/store'
  import MaterialStatsCard from '../../components/base/MaterialStatsCard.vue'
  import { mapState, mapActions, mapMutations } from 'vuex'
  import ContaExtratoStore, { BOOTSTRAP, LOAD_CONTAS, LOAD_BATCH, LOAD_SALDO, PERIODO } from '@/store/modules/contaExtrato'
  import { sortDateTime } from '../../utils/sorts'
  import { currencyFormatter } from '../../utils/formatter'
  import jsPDF from 'jspdf'
  import XLSX from 'xlsx'
  import moment from 'moment'

  export default {
    name: 'FluxoCaixaContaExtrato',
    components: {
      MaterialStatsCard,
    },
    data () {
      return {
        currencyFormatter,
        conta_id: null,
        displayDetalhe: false,
        detalheExtrato: {
          tipo: '',
          valor: '',
          data: '',
          descricao: '',
          origem: '',
          destino: '',
        },
        tab: 0,
        n_tabs: 3,
        n_cards: 4,
        data_filtro: 'from',
        actions: [
          {
            title: 'Detalhes',
            color: 'blue darken-3',
            click: item => this.getDetalheExtrato(item),
            icon: 'mdi-eye-plus-outline',
            permission: 'excluir_lancamento_faturamento',
          },
        ].filter(action => store.getters.hasPermission(action.permission)),
        headers: [
          { align: 'left', sortable: false, groupable: false, floatingfilter: false, text: 'Tipo', value: 'tipo', width: 'auto', report: true },
          { align: 'center', sortable: false, groupable: false, floatingfilter: false, text: 'Data', value: 'data', width: 'auto', sort: sortDateTime, report: true },
          { align: 'left', sortable: false, groupable: false, floatingfilter: false, text: 'Descricao', value: 'conta_dobra', width: 'auto', report: true },
          { align: 'right', sortable: false, groupable: false, floatingfilter: false, text: 'Valor', value: 'valor', width: 'auto', sum: 'Money', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
          { text: '', value: 'actions', sortable: false, groupable: false, floatingfilter: false, width: '20' },
        ],
      }
    },
    computed: {
      ...mapState('contaExtrato', ['loading', 'contas', 'loadedContas', 'contaExtrato', 'saldo']),
    },
    created () {
      if (!store.getters.hasPermission('list_extrato_conta')) {
        this.$router.push({
          path: '/controleacesso/sempermissao',
        })
      }

      if (!this.$store.state.contaExtrato) { this.$store.registerModule('contaExtrato', ContaExtratoStore) }

      let data_i = new Date()

      data_i = new Date(data_i.getFullYear(), data_i.getMonth(), 1)
      data_i = data_i.toISOString().slice(0, 10)

      this.data_filtro = data_i
      this.conta_id = this.$route.query.id

      this.PERIODO({ data_i })
      this.BOOTSTRAP(this.conta_id)
    },
    methods: {
      ...mapActions('contaExtrato', [BOOTSTRAP, LOAD_CONTAS, LOAD_BATCH, LOAD_SALDO]),
      ...mapMutations('contaExtrato', [PERIODO]),
      handleDataFilter () {
        let data = new Date(this.data_filtro + '-01')
        data = data.toISOString().slice(0, 10)
        this.PERIODO({ data_i: data })
        this.BOOTSTRAP(this.conta_id)
      },
      changeConta () {
        this.displayDetalhe = false
        this.BOOTSTRAP(this.conta_id)
      },
      getDetalheExtrato (dado) {
        if (dado.tipo === 'Transferência' && dado.tipo_movimento === 'E') {
          this.detalheExtrato.origem = dado.conta_dobra
          this.detalheExtrato.destino = dado.conta.instituicao_financeira
        } else if (dado.tipo === 'Transferência' && dado.tipo_movimento === 'S') {
          this.detalheExtrato.origem = dado.conta.instituicao_financeira
          this.detalheExtrato.destino = dado.conta_dobra
        } else {
          this.detalheExtrato.origem = null
          this.detalheExtrato.destino = null
        }

        this.detalheExtrato.tipo = dado.tipo
        this.detalheExtrato.valor = dado.tipo_movimento === 'S' ? dado.valor * -1 : dado.valor
        this.detalheExtrato.data = dado.data
        this.detalheExtrato.descricao = dado.observacao

        this.displayDetalhe = true
      },
      exportarExcel () {
        const data = this.contaExtrato
        const banco = this.contas.find(el => el.id === this.conta_id)
        const titulo = 'Extrato ' + banco.instituicao_financeira + ' - ' + moment(this.data_filtro).format('MM/YYYY')

        try {
          var table = [
            [titulo],
            [''],
            ['Tipo', 'Data', 'Descrição', 'Valor'],
          ]
          data.forEach(item => {
            table.push([
              item.tipo,
              moment(item.data).format('DD/MM/YYYY'),
              item.conta_dobra,
              parseFloat(item.valor),
            ])
          })
          var ws = XLSX.utils.aoa_to_sheet(table)

          const colWidths = table[3].map((_, colIndex) => table.reduce((maxWidth, currentRow) => {
            const cellValue = currentRow[colIndex]
            const cellWidth = (typeof cellValue === 'string' ? cellValue.length : 0)
            return Math.max(maxWidth, cellWidth)
          }, 0))

          ws['!cols'] = colWidths.map(width => ({ wch: width + 2 }))

          var wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, 'Extrato')

          XLSX.writeFile(wb, 'Extrato.xlsx')
        } catch (e) {
          console.log(e)
        }
      },
      exportarPdf () {
        const spaceLine = 7
        const fontSize = spaceLine + 1
        const fontTitleSize = fontSize * 2
        const margin = { left: 10, right: 10, bottom: 10, top: 28 }
        const banco = this.contas.find(el => el.id === this.conta_id)
        const titulo = 'Extrato ' + banco.instituicao_financeira + ' - ' + moment(this.data_filtro).format('MM/YYYY')

        function addHeader () {
          doc.addImage(window.location.origin + '/img/logo_branca.png', 'png', margin.left, 5, fontSize * 1.8, fontSize * 1.8)
          doc.setFontSize(fontTitleSize)
          doc.text(titulo, docWidth / 2, 15, { align: 'center' })
          doc.setFontSize(fontSize)
          doc.text(dateNow, docWidth - margin.right, (20), { align: 'right' })
          doc.line(5, (24), docWidth - 5, (24))
        }

        function addFooter (pageNumber) {
          doc.setFontSize(fontSize - 1)
          doc.text(dateNow, margin.left - 5, docHeight - margin.bottom)
          doc.text('Página ' + pageNumber + ' / ' + totalPages, docWidth + 17, docHeight - margin.bottom, { align: 'right' })
        }

        var doc = new jsPDF({
          orientation: 'landscape',
        })
        var totalPages = '{total_pages_count_string}'
        const dateNow = (new Date()).toLocaleString()
        const docWidth = doc.internal.pageSize.width
        const docHeight = doc.internal.pageSize.height

        var cols = [
          { title: 'Tipo', dataKey: 'tipo' },
          { title: 'Data', dataKey: 'data' },
          { title: 'Descrição', dataKey: 'conta_dobra' },
          { title: 'Valor', dataKey: 'valor' },
        ]

        var total = this.contaExtrato.reduce((sum, el) => sum + Number(el.valor), 0)

        // var body = [...this.contaExtrato.map(el => [el.tipo, el.data, el.conta_dobra, el.valor]),
        //             [{
        //               content: `Total = R$ ${ currencyFormatter(total)}`,
        //               colSpan: 4,
        //               styles: { fillColor: '#D3D3D3' },
        //               align: 'right',
        //             }]]

        doc.autoTable({
          theme: 'plain',
          headStyles: {
            textColor: '#FFFFFF',
            fillColor: '#1E88E5',
          },
          columns: cols,
          body: this.contaExtrato,
          foot: [{ tipo: 'Saldo Final', data: null, conta_dobra: null, valor: 'R$ ' + currencyFormatter(parseFloat(total), 2) }],
          startY: 27,
          pageBreak: 'auto',
          margin: { left: 5, top: margin.top, bottom: (margin.bottom + 5), right: 5 },
          didParseCell: function (data) {
            console.log(data)
            if (data.section === 'body') {
              if (data.column.dataKey === 'data') {
                if (data.cell.text[0] !== '') {
                  data.cell.text = moment(data.cell.text[0]).format('DD/MM/YYYY')
                }
              }
              if (data.column.dataKey === 'valor') {
                if (data.cell.text[0] !== '') {
                  data.cell.text = 'R$ ' + currencyFormatter(parseFloat(data.cell.text), 2)
                }
              }
            }
          },
          didDrawPage: (data) => {
            addHeader()
            addFooter(data.pageNumber)
          },
        })
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPages)
        }

        doc.save('Extrato' + '.pdf')
      },
    },
  }
</script>
