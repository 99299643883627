import { createUnidadeNegocio } from '@/models/UnidadeNegocio'

const pessoaTransformer = (data) => {
  return {
    email: data.email,
  }
}

const pessoaFisicaTransformer = (data) => {
  return {
    cpf: data.cpf,
    data_nascimento: data.data_nascimento,
    nome: data.nome,
    rg: data.rg,
    sexo: data.sexo,
  }
}
const pessoaJuridicaTransformer = (data) => {
  return {
    cnpj: data.cnpj,
    razao_social: data.razao_social,
    nome_fantasia: data.nome_fantasia,
    inscricao_estadual: data.inscricao_estadual,
  }
}

const enderecoTransformer = (data) => {
  return {
    bairro: data.bairro,
    cep: data.cep,
    complemento: data.complemento,
    logradouro: data.logradouro,
    numero: data.numero,
    cidade: parseInt(data.cidade.id),
    estado: parseInt(data.estado.id),
    pais: parseInt(data.pais.id),
    regional: data.regional ? data.regional.id : null,
  }
}
const telefonesTransformer = (data, format) => {
  return {
    telefones: data,
  }
}
const unidadeNegocioTransformerParaGestor = (data) => {
  return {
    id: data.id,
  }
}

const unidadeNegocioTransformer = (data) => {
  return createUnidadeNegocio({
    id: data.id,
    cod_centro: data.cod_centro,
    email_adm_clube: data.email_adm_clube,
    email: data.email,
    telefones: data.telefones,
    gestor_id: data.gestor.id,
    ...pessoaJuridicaTransformer(data),
    ...enderecoTransformer(data.endereco),
    ...telefonesTransformer(data.telefones),
  })
}

const gestorTransformer = (data) => {
  return {
    unidades: data.unidades.map(
      (unidade) => unidadeNegocioTransformerParaGestor(unidade).id,
    ),
    id: data.id,
    ...pessoaTransformer(data),
    ...pessoaFisicaTransformer(data),
    ...telefonesTransformer(data.telefones),
    role: data.roles.id,
  }
}

const funcionarioTransformer = (data) => {
  return {
    id: data.id,
    ...pessoaTransformer(data),
    ...pessoaFisicaTransformer(data),
    ...telefonesTransformer(data.telefones),
  }
}

const regionalTransformer = (data) => {
  return {
    estados: data.estados.map((estado) => parseInt(estado.id)),
    nome: data.nome,
    id: data.id,
  }
}

const calculoTransformer = (data) => {
  return {
    user: data.user,
    status: data.status,
    data_referencia: data.data_referencia,
    id: data.id,
  }
}

const eventoTransformer = (data) => {
  return {
    descricao: data.descricao,
    codigo: data.codigo,
    id: data.id,
    tipo_evento: data.tipo_evento,
    tipo_calculo: data.tipo_calculo,
    tipo_variacao: data.tipo_variacao,
    tipo_vigencia: data.tipo_vigencia,
    meses_vigencia: data.meses_vigencia,
    tipo_ocorrencia: data.tipo_ocorrencia,
    tipo_lancamento: data.tipo_lancamento,
    tipo_agregate: data.tipo_agregate,
    evento_ocorrencia_id: data.evento_ocorrencia_id,
    evento_agregate_id: data.evento_agregate_id,
    valor_evento: data.valor_evento,
    percent_transf: data.percent_transf,
    evento_transf_id: data.evento_transf_id,
    tipo_variacao_transf: data.tipo_variacao_transf,
    status: data.status,
  }
}

const categoriaTransformer = (data) => {
  return {
    id: data.id,
    codigo: data.codigo,
    descricao: data.descricao,
    tipo: data.tipo,
    tipo_pessoa: data.tipo_pessoa,
    status: data.status,
  }
}

const fluxoCaixaTransformer = (data) => {
  return {
    id: data.id,
    valor_pagamento: data.valor_pagamento,
    status_pagamento: data.status_pagamento,
    data_referencia: data.data_referencia,
    data_vencimento: data.data_vencimento,
    data_pagamento: data.data_pagamento,
    categoria_id: data.categoria.id,
    conta_id: data.conta.id,
    pessoa_id: data.pessoa ? data.pessoa.id : data.pessoa,
    observacao: data.observacao
  }
}

const fornecedorTransformer = (data) => {
  if (data.pessoaFis) {
    return {
      id: data.id,
      codigo: data.codigo,
      pessoaFis: true,
      data_ingresso: data.data_ingresso,
      ...pessoaTransformer(data),
      ...enderecoTransformer(data.endereco),
      ...pessoaFisicaTransformer(data),
      ...telefonesTransformer(data.telefones),
      role: data.roles.id,
      eventos: data.eventos,
    }
  } else {
    return {
      id: data.id,
      codigo: data.codigo,
      pessoaFis: false,
      data_ingresso: data.data_ingresso,
      ...pessoaTransformer(data),
      ...pessoaJuridicaTransformer(data),
      ...enderecoTransformer(data.endereco),
      ...telefonesTransformer(data.telefones),
      role: data.roles.id,
      eventos: data.eventos,
    }
  }
}

const associadoTransformer = (data) => {
  if (data.pessoaFis) {
    return {
      id: data.id,
      codigo: data.codigo,
      pessoaFis: true,
      data_ingresso: data.data_ingresso,
      ...pessoaTransformer(data),
      ...enderecoTransformer(data.endereco),
      ...pessoaFisicaTransformer(data),
      ...telefonesTransformer(data.telefones),
      role: data.roles.id,
      eventos: data.eventos,
    }
  } else {
    return {
      id: data.id,
      codigo: data.codigo,
      pessoaFis: false,
      data_ingresso: data.data_ingresso,
      ...pessoaTransformer(data),
      ...pessoaJuridicaTransformer(data),
      ...enderecoTransformer(data.endereco),
      ...telefonesTransformer(data.telefones),
      role: data.roles.id,
      eventos: data.eventos,
    }
  }
}

const profileTransformer = (data) => {
  return {
    avatar: data.avatar,
    ...pessoaTransformer(data),
    ...(data.cpf
      ? pessoaFisicaTransformer(data)
      : pessoaJuridicaTransformer(data)),
    ...(data.endereco
      ? enderecoTransformer(data.endereco)
      : { endereco: null }),
    telefones: data.telefones || [],
  }
}

export {
  pessoaTransformer,
  pessoaFisicaTransformer,
  enderecoTransformer,
  unidadeNegocioTransformer,
  pessoaJuridicaTransformer,
  gestorTransformer,
  funcionarioTransformer,
  fornecedorTransformer,
  associadoTransformer,
  profileTransformer,
  regionalTransformer,
  eventoTransformer,
  categoriaTransformer,
  fluxoCaixaTransformer,
  calculoTransformer,
}
