<template>
  <base-loading v-if="loading" />
  <v-container
    v-else
    fluid
    style="margin-bottom: 24px; padding: 0px;"
  >
    <crud-list
      v-model="despesa.dados"
      :permission="'list_fluxo_caixa'"
      :headers="headers"
      :actions="actions"
      :sort-desc="[false]"
      :slots="['item.status_pagamento', 'item.valor_pagamento', 'item.data_referencia', 'item.data_vencimento', 'item.data_pagamento']"
      total-column
      :export-pdf-xlsx="true"
    >
      <template v-slot:[`item.valor_pagamento`]="{ item }">
        <span>R$ {{ currencyFormatter(item.valor_pagamento) }}</span>
      </template>
      <template v-slot:[`item.status_pagamento`]="{ item }">
        <v-chip
          outlined
          dense
          :color="(item.status_pagamento == 'Recebido' || item.status_pagamento == 'Pago') ? 'green' : (item.status_pagamento == 'Vencido' ? 'red' : 'orange')"
          style="min-width: 70px; justify-content: center;"
        >
          {{ item.status_pagamento }}
        </v-chip>
      </template>
      <template v-slot:[`item.data_referencia`]="{ item }">
        <span style="text-align: center;">{{ moment(item.data_referencia).format('DD/MM/YYYY') }}</span>
      </template>
      <template v-slot:[`item.data_vencimento`]="{ item }">
        <span style="text-align: center;">{{ moment(item.data_vencimento).format('DD/MM/YYYY') }}</span>
      </template>
      <template v-slot:[`item.data_pagamento`]="{ item }">
        <span style="text-align: center;">{{ item.data_pagamento ? moment(item.data_pagamento).format('DD/MM/YYYY') : '' }}</span>
      </template>
      <template slot="datas">
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <data-field
            v-model="data_filtro"
            :label="'Mês de Referência'"
            :mes-ano="true"
            @set="handleDataFilter()"
          />
        </v-col>
      </template>
      <template slot="cards">
        <v-col
          v-for="j in Array.from(Array(n_cards).keys())"
          :key="j"
          cols="12"
          sm="6"
          lg="4"
          xl="4"
        >
          <material-stats-card
            :title="despesa.cards_design.title[j]"
            :value="'R$ ' + currencyFormatter(despesa.cards_design.value[j])"
            :icon="despesa.cards_design.icon[j]"
            :color="despesa.cards_design.color[j]"
            elevation="3"
          />
        </v-col>
      </template>
      <template
        v-if="hasPermission('inserir_fluxo_caixa')"
        v-slot:btnCadastro
      >
        <v-col>
          <v-btn
            class="text-none text-white"
            color="red"
            rounded
            block
            style="color: white;"
            @click.stop="NEW_DESPESA"
          >
            <v-icon
              dark
              left
            >
              mdi-trending-down
            </v-icon>
            Nova Despesa
          </v-btn>
        </v-col>
      </template>
    </crud-list>
    <modal-lateral />
  </v-container>
</template>
<script>
  import MaterialStatsCard from '../../components/base/MaterialStatsCard.vue'
  import ModalLateral from '../../components/base/ModalLateral.vue'
  import store from '@/store'
  import MovimentacaoDespesaStore, { BOOTSTRAP, DELETE_MOVIMENTACAO, EDIT_DESPESA, NEW_DESPESA, PERIODO } from '@/store/modules/movimentacaoDespesa'
  import ModalLateralStore, { CLOSE } from '@/store/modules/forms/modalLateral'
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
  import { sortCurrency, sortDateTime } from '../../utils/sorts'
  import { currencyFormatter } from '../../utils/formatter'
  import moment from 'moment'

  export default {
    components: {
      MaterialStatsCard,
      ModalLateral,
    },
    data () {
      return {
        moment,
        tab: 0,
        n_tabs: 3,
        n_cards: 3,
        currencyFormatter,
        data_filtro: 'from',
        actions: [
          {
            title: 'Editar Lançamento',
            color: 'info darken-3',
            click: item => this.EDIT_DESPESA(item),
            icon: 'mdi-pencil',
            permission: 'editar_fluxo_caixa',
          },
          {
            title: 'Excluir Lançamento',
            color: 'red darken-3',
            click: item => this.DELETE_MOVIMENTACAO(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_fluxo_caixa',
          },
        ].filter(action => store.getters.hasPermission(action.permission)),
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, floatingfilter: false, width: '20' },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Status', value: 'status_pagamento', width: 'auto', report: true },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Data Referência', value: 'data_referencia', width: 'auto', sort: sortDateTime, report: true },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Data Vencimento', value: 'data_vencimento', width: 'auto', sort: sortDateTime, report: true },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Data Pagamento', value: 'data_pagamento', width: 'auto', sort: sortDateTime, report: true },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Categoria', value: 'categoria.descricao', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Pessoa', value: 'nomepessoa', width: 'auto', report: true },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor', value: 'valor_pagamento', width: 'auto', sort: sortCurrency, sum: 'Money', report: true, styleReport: { halign: 'right', decimal: 'money', sum: true } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Carteira', value: 'conta.instituicao_financeira', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Observação', value: 'observacao', width: 'auto', report: true },
        ],
      }
    },
    computed: {
      ...mapState('movimentacaoDespesa', ['loading', 'despesa']),
      ...mapState('form/modalLateral', ['dialog']),
      ...mapGetters(['hasPermission']),
    },
    created () {
      if (!this.$store.state.movimentacaoDespesa) { this.$store.registerModule('movimentacaoDespesa', MovimentacaoDespesaStore) }
      if (!this.$store.state.form.modalLateral) { this.$store.registerModule(['form', 'modalLateral'], ModalLateralStore) }

      let data_i = new Date()

      data_i = new Date(data_i.getFullYear(), data_i.getMonth(), 1)
      data_i = data_i.toISOString().slice(0, 10)

      this.data_filtro = data_i

      this.PERIODO({ data_i })

      this.BOOTSTRAP()

      this.$store.watch(('dialog', (val) => {
        val || this.CLOSE()
      }))
    },
    methods: {
      ...mapActions('movimentacaoDespesa', [BOOTSTRAP, DELETE_MOVIMENTACAO, EDIT_DESPESA, NEW_DESPESA]),
      ...mapMutations('form/modalLateral', [CLOSE]),
      ...mapMutations('movimentacaoDespesa', [PERIODO]),
      handleDataFilter () {
        const data_i = this.data_filtro.substring(0, 7) + '-01'
        this.PERIODO({ data_i })
        this.BOOTSTRAP()
      },
    },
  }
</script>
