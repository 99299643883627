export class Funcionario {
  constructor({
    id,
    cpf,
    rg,
    nome,
    email,
    data_nascimento,
    sexo,
    telefones,
  } = {
      id: '',
      cpf: '',
      rg: '',
      nome: '',
      email: '',
      data_nascimento: '',
      sexo: '',
      telefones: [],
    }) {
    this.id = id
    this.cpf = cpf
    this.rg = rg
    this.nome = nome
    this.email = email
    this.data_nascimento = data_nascimento
    this.sexo = sexo
    this.telefones = telefones
  }
}

export function createFuncionario(data) {
  return Object.freeze(new Funcionario(data))
}
