import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const BOOTSTRAP = 'BOOTSTRAP'
export const LOAD_EXTRATO = 'LOAD_EXTRATO'
export const RESET_STATE = 'RESET_STATE'
export const PERIODO = 'PERIODO'
export const TYPE = 'TYPE'
export const BANCO = 'BANCO'

const getDefaultState = () => {
  return {
    loading: true,
    data: 'from',
    banco: '',
    type: '',
    extratos: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [PERIODO] (state, { data_filtro }) {
    state.data = data_filtro
  },
  [BANCO] (state, { banco_filtro }) {
    state.banco = banco_filtro
  },
  [TYPE] (state, { type_pagamento }) {
    state.type = type_pagamento
  },
}

const actions = {
  async [LOAD_EXTRATO] ({ commit, state }) {
    // await api.listEntidade('lancamentoFaturamento/' + state.data + '/' + state.banco).then(
    //   extratos => {
    //     commit(SET_STATE, { extratos })
    //   },
    // )
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_EXTRATO)
    commit(SET_STATE, { loading: false })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
