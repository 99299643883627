<template>
  <div class="content">
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <crud-cadastro
        :loading="loading"
      >
        <crud-card title="Identificação da Categoria">
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                ref="codigo"
                v-model="codigo"
                v-mask="maskCodigo"
                dense
                type="string"
                autocomplete="off"
                label="Código*"
                :disabled="isEditing"
                :rules="[rules.required,validaCodigo]"
                validate-on-blur
                outlined
                rounded
                maxlength="7"
                :error-messages="errors"
                @blur="validaGrupo"
                @click="limpaErro"
              />
            </v-col>
            <v-col
              cols="12"
              md="10"
            >
              <v-text-field
                v-model="descricao"
                dense
                outlined
                rounded
                autocomplete="off"
                label="Descrição*"
                validate-on-blur
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-if="categoriaTitulo"
              cols="6"
              md="2"
            >
              <v-radio-group
                v-model="tipo"
                :disabled="codigo === ''"
              >
                <template v-slot:label>
                  <div><strong>Tipo</strong></div>
                </template>
                <v-radio value="0">
                  <template v-slot:label>
                    <div><strong class="green--text">Receita</strong></div>
                  </template>
                </v-radio>
                <v-radio value="1">
                  <template v-slot:label>
                    <div><strong class="error--text">Despesa</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              v-if="categoriaTitulo"
              cols="6"
              md="2"
            >
              <v-radio-group
                v-model="tipo_pessoa"
                :disabled="codigo === ''"
              >
                <template v-slot:label>
                  <div><strong>Tipo de Pessoa</strong></div>
                </template>
                <v-radio value="0">
                  <template v-slot:label>
                    <div><strong>Associado</strong></div>
                  </template>
                </v-radio>
                <v-radio value="1">
                  <template v-slot:label>
                    <div><strong>Fornecedor</strong></div>
                  </template>
                </v-radio>
                <v-radio value="2">
                  <template v-slot:label>
                    <div><strong>Funcionário</strong></div>
                  </template>
                </v-radio>
                <v-radio value="3">
                  <template v-slot:label>
                    <div><strong>Avulso</strong></div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </crud-card>
        <v-row
          class="pa-3"
          justify="center"
          justify-sm="end"
        >
          <v-btn
            class="mr-3"
            color="error"
            rounded
            @click="$router.push({ path: `/${$user.get().role}/fluxoCaixaCategorias` })"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            :disabled="desabilitaBotao"
            rounded
            @click="submitForm"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            {{ isEditing ? 'Salvar' : 'Cadastrar' }}
          </v-btn>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import CategoriaStore, { BOOTSTRAP_PROFILE, SUBMIT } from '@/store/modules/forms/categoria'
  import { mapState, mapActions } from 'vuex'
  import api from '@/api/api'
  export default {
    data () {
      return {
        valid: false,
        rules: rules,
        alertCodigo: false,
        snackbar: { message: '', show: false },
        maskCodigo: '###.####',
        tipos: ['Receita', 'Despesa'],
      }
    },
    computed: {
      ...mapState('form/categoria', ['loading', 'isEditing']),
      ...mapFields('form', [
        'data.id',
        'data.status',
        'data.codigo',
        'data.descricao',
        'data.tipo',
        'data.tipo_pessoa',
      ],
      ),
      desabilitaBotao () {
        return this.loading || !this.valid
      },
      errors () {
        const errors = [];
        (this.alertCodigo) && errors.push('Crie categoria título')
        return errors
      },
      categoriaTitulo () {
        if ((parseInt(this.codigo.substring(4, 7)) === 0 || this.codigo.substring(4, 7) === '')) {
          return false
        }
        return true
      },
    },
    created () {
      if (!this.$store.state.form.categoria) { this.$store.registerModule(['form', 'categoria'], CategoriaStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/categoria', [BOOTSTRAP_PROFILE, SUBMIT]),
      remove (item) {
        const index = this.tipos.indexOf(item.id)
        if (index >= 0) this.tipos.splice(index, 1)
      },
      submitForm () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
      validaCodigo (codigo) {
        let retorno = true
        if (codigo.length < 7) {
          retorno = 'Código inválido'
        } else if (parseInt(codigo.substring(0, 3)) <= 0) {
          retorno = 'Código inválido'
        }
        return retorno
      },
      async validaGrupo () {
        if (parseInt(this.codigo.substring(0, 3)) > 0 && this.codigo.length === 7 && this.codigo.substring(4, 7) !== '000') {
          const codigoTitulo = this.codigo.substring(0, 3) + '.000'
          const respostaApi = await api.getEntidade('categoria/codigo', codigoTitulo)

          if (respostaApi.data[0].length <= 0) {
            this.alertCodigo = true
            this.snackbar = {
              show: true,
              message: 'Crie a categoria titulo com código: ' + codigoTitulo + ' antes de criar esta categoria!',
            }
          }
        }

        return true
      },
      limpaErro () {
        this.alertCodigo = false
      },
    },
  }
</script>
