<template>
  <v-row justify="center">
    <v-dialog
      v-model="conta_dialog"
      content-class="lateral-dialog"
      hide-overlay
      persistent
      transition="slide-x-reverse-transition"
      origin="right"
    >
      <base-snack-bar
        v-model="snackbar.show"
        :message="snackbar.message"
      />
      <v-card class="lateral-card">
        <v-toolbar
          dark
          color="#557de3"
        >
          <v-btn
            icon
            dark
            @click="cancel"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-col
            cols="11.5"
          >
            <span
              v-if="loading"
              class="headline"
            >Aguarde carregando Contas...</span>
            <span
              v-else
              class="headline"
            >{{ isEditing ? 'Editar Conta' : 'Adicionar Nova Conta' }}</span>
          </v-col>
          <!-- <v-btn
            icon
            dark
            @click.stop="cancel"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click.stop="cancel"
            >
              Save
            </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-card-text
          outlined
        >
          <base-loading v-if="loading" />
          <div v-else>
            <v-form
              ref="form"
              dense
              lazy-validation
            >
              <v-row>
                <v-col>
                  <v-card
                    elevation="0"
                  >
                    <v-row
                      class="px-0 py-0"
                    >
                      <!-- <v-col
                        cols="8"
                        md="8"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <base-vuetify-money
                          v-model="saldo"
                          :options="money"
                          maxlength="14"
                          :rules="[rules.required]"
                          label="Saldo*"
                          validate-on-blur
                          dense
                          outlined
                          hide-details
                          rounded
                        />
                      </v-col> -->
                      <v-col
                        cols="12"
                        md="12"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <v-radio-group
                          v-model="cor"

                          :rules="[rules.required]"
                        >
                          <template v-slot:label>
                            <div><strong>Cores*</strong></div>
                          </template>
                          <v-row>
                            <v-col>
                              <v-radio value="#2196F3">
                                <template v-slot:label>
                                  <div style="background-color: #2196F3;  color: white; width: 100%; padding: 10px;">
                                    <strong>Azul</strong>
                                  </div>
                                </template>
                              </v-radio>
                            </v-col>
                            <v-col>
                              <v-radio value="#F44336">
                                <template v-slot:label>
                                  <div style="background-color: #F44336; color: white; width: 100%; padding: 10px;">
                                    <strong>Vermelho</strong>
                                  </div>
                                </template>
                              </v-radio>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-radio value="#4CAF50">
                                <template v-slot:label>
                                  <div style="background-color: #4CAF50; color: white; width: 100%; padding: 10px;">
                                    <strong>Verde</strong>
                                  </div>
                                </template>
                              </v-radio>
                            </v-col>
                            <v-col>
                              <v-radio value="#FDD835">
                                <template v-slot:label>
                                  <div style="background-color: #FDD835; color: white; width: 100%; padding: 10px;">
                                    <strong>Amarelo</strong>
                                  </div>
                                </template>
                              </v-radio>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-radio value="#7E57C2">
                                <template v-slot:label>
                                  <div style="background-color: #7E57C2; color: white; width: 100%; padding: 10px;">
                                    <strong>Roxo</strong>
                                  </div>
                                </template>
                              </v-radio>
                            </v-col>
                            <v-col>
                              <v-radio value="#FF9800">
                                <template v-slot:label>
                                  <div style="background-color: #FF9800; color: white; width: 100%; padding: 10px;">
                                    <strong>Laranja</strong>
                                  </div>
                                </template>
                              </v-radio>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-radio value="#9E9E9E">
                                <template v-slot:label>
                                  <div style="background-color: #9E9E9E; color: white; width: 100%; padding: 10px;">
                                    <strong>Cinza</strong>
                                  </div>
                                </template>
                              </v-radio>
                            </v-col>
                            <v-col>
                              <v-radio value="#FF80AB">
                                <template v-slot:label>
                                  <div style="background-color: #FF80AB; color: white; width: 100%; padding: 10px;">
                                    <strong>Rosa</strong>
                                  </div>
                                </template>
                              </v-radio>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-radio value="#000000">
                                <template v-slot:label>
                                  <div style="background-color: #000000; color: white; width: 100%; padding: 10px;">
                                    <strong>Preto</strong>
                                  </div>
                                </template>
                              </v-radio>
                            </v-col>
                            <v-col>
                              <v-radio value="#795548">
                                <template v-slot:label>
                                  <div style="background-color: #795548; color: white; width: 100%; padding: 10px;">
                                    <strong>Marron</strong>
                                  </div>
                                </template>
                              </v-radio>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </v-col>
                      <v-col
                        v-if="!isEditing"
                        cols="8"
                        md="6"
                        align-self="center"
                        class="px-2 py-2"
                      >
                        <base-vuetify-money
                          v-model="saldo"
                          :options="money"
                          maxlength="14"
                          :rules="[rules.required]"
                          label="Saldo inicial*"
                          validate-on-blur
                          dense
                          outlined
                          hide-details
                          rounded
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        align-self="center"
                        class="pl-2 pr-1 pb-2"
                      >
                        <v-text-field
                          v-model="instituicao_financeira"
                          label="Instituição Financeira*"
                          validate-on-blur
                          outlined
                          rounded
                          :rules="[rules.maxLength(255, 'Max. 255 caracteres'), rules.required]"
                          no-resize
                          maxlength="255"
                          hide-details
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        align-self="center"
                        class="pl-2 pr-1 pb-2"
                      >
                        <v-text-field
                          v-model="agencia"
                          label="Agência*"
                          validate-on-blur
                          outlined
                          rounded
                          :rules="[rules.maxLength(6, 'Max. 6 caracteres'), rules.required]"
                          no-resize
                          maxlength="6"
                          hide-details
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        align-self="center"
                        class="pl-2 pr-1 pb-2"
                      >
                        <v-text-field
                          v-model="conta"
                          label="Conta*"
                          validate-on-blur
                          outlined
                          rounded
                          :rules="[rules.maxLength(10, 'Max. 10 caracteres'), rules.required]"
                          no-resize
                          maxlength="10"
                          hide-details
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        align-self="center"
                        class="pl-2 pr-1 pb-2"
                      >
                        <v-textarea
                          v-model="descricao"
                          label="Descrição"
                          validate-on-blur
                          outlined
                          rounded
                          :rules="[rules.maxLength(255, 'Max. 255 caracteres')]"
                          height="50"
                          no-resize
                          maxlength="255"
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <div>
            <v-card-actions
              class="justify-end mt-0"
            >
              <v-btn
                class="text-none text-white"
                color="error"
                rounded
                @click="cancel"
              >
                <v-icon
                  dark
                  left
                >
                  mdi-minus-circle
                </v-icon>
                Cancelar
              </v-btn>
              <v-btn
                class="text-none text-white"
                color="success"
                depressed
                rounded
                @click="submit"
              >
                <v-icon
                  dark
                  left
                >
                  mdi-checkbox-marked-circle
                </v-icon>
                Salvar
              </v-btn>
            </v-card-actions>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import store from '@/store'
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import { CLOSE_CONTA, SET_STATE, SUBMIT } from '@/store/modules/forms/conta'
  import { mapMutations, mapState, mapActions } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import Swal from 'sweetalert2'

  export default {
    name: 'FluxoCaixaContasDialog',
    inheritAttrs: false,

    data () {
      return {
        rules,
        currencyFormatter,
        valid: false,
        snackbar: {
          message: '',
          show: false,
          color: 'error',
        },
        money: {
          locale: 'pt-BR',
          precision: 2,
        },
        escolheCategoria: null,
        escolheDescricao: null,
        escolheOrigem: null,
        valor: 0,
      }
    },
    computed: {
      ...mapState('form/conta', ['loading', 'conta_dialog', 'isEditing', 'loadedConta']),
      ...mapFields('form', [
        'data.id',
        'data.saldo',
        'data.instituicao_financeira',
        'data.agencia',
        'data.conta',
        'data.descricao',
        'data.cor',
        'data.somar',
      ]),
    },
    created () {
    },
    methods: {
      ...mapMutations('form/conta', [CLOSE_CONTA, SET_STATE]),
      ...mapActions('form/conta', [SUBMIT]),
      cancel () {
        this.CLOSE_CONTA()
      },
      submit () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }

        this.SUBMIT({ router: this.$router, user: this.$user })
      },
    },
  }
</script>
<style lang="scss">
  .lateral-dialog {
    margin: 0px;
    height: 100%;
    width: 360px;
    position: absolute;
      right: 0;
      margin: 0;
      min-height: 100%;
  }

  .lateral-card {
    height: 100%;
  }

  div:has(> div[class*='lateral-dialog']) {
    margin-top: 57px;
  }
</style>
