import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const BOOTSTRAP = 'BOOTSTRAP'
export const LOAD_MOVIMENTACAO = 'LOAD_MOVIMENTACAO'
export const DELETE_MOVIMENTACAO = 'DELETE_MOVIMENTACAO'
export const NEW_DESPESA = 'NEW_DESPESA'
export const EDIT_DESPESA = 'EDIT_DESPESA'
export const RESET_STATE = 'RESET_STATE'
export const PERIODO = 'PERIODO'

const getDefaultState = () => {
  return {
    loading: true,
    data_i: 'from',
    type: '',
    despesa: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE](state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE](state) {
    Object.assign(state, getDefaultState())
  },
  [PERIODO](state, { data_i }) {
    state.data_i = data_i
  },
}

const actions = {
  async [LOAD_MOVIMENTACAO]({ commit, state }) {
    await api.listEntidade('fluxo_caixa_movimentacao/despesa/' + state.data_i).then(
      response => {
        const despesa = response
        despesa.dados = response.dados.map(row => {
          if (row.pessoafisica || row.pessoajuridica) {
            row.nomepessoa = row.pessoafisica ? row.pessoafisica.nome : row.pessoajuridica.razao_social
          } else {
            row.nomepessoa = "Avulso"
          }
          return row
        })
        commit(SET_STATE, { despesa })
      },
    )
  },
  [NEW_DESPESA]({ dispatch }) {
    dispatch('form/modalLateral/BOOTSTRAP_PROFILE', { id: null, type: 'despesa', route: 'movimentacaoDespesa' }, { root: true })
  },
  [EDIT_DESPESA]({ dispatch }, movimentacao) {
    dispatch('form/modalLateral/BOOTSTRAP_PROFILE', { id: movimentacao.id, type: 'despesa', route: 'movimentacaoDespesa' }, { root: true })
  },
  async [BOOTSTRAP]({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_MOVIMENTACAO)
    commit(SET_STATE, { loading: false })
  },
  async [DELETE_MOVIMENTACAO]({ dispatch, commit, state }, movimentacao) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir essa Movimentação?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('fluxo_caixa', movimentacao.id).then(
          result => result,
          (error) => {
            Swal.fire({
              icon: 'warning',
              title: 'Atenção!',
              text: error.message
                ? error.message
                : 'Não foi possivel excluir a Movimentação!',
            })
          },
        )
      },
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'A movimentação foi excluída com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        })
        dispatch(BOOTSTRAP)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
