const router = function (permissions) {
  return ({
    path: 'fluxoCaixaMovimentacoes',
    component: () => import('@/views/FluxoCaixaMovimentacoes/FluxoCaixaMovimentacoesModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/FluxoCaixaMovimentacoes/FluxoCaixaMovimentacoesList.vue'),
        meta: { permissions, title: 'Movimentações do Fluxo de Caixa' },
      },
    ],
  })
}

export default {
  router,
}
