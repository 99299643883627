export class FluxoCaixa {
  constructor({
    id,
    valor_pagamento,
    status_pagamento,
    data_referencia,
    data_vencimento,
    data_pagamento,
    categoria_id,
    conta_id,
    pessoa_id,
    observacao,
  } = {
      id: '',
      valor_pagamento: 0,
      status_pagamento: false,
      data_referencia: (new Date()).toISOString().slice(0, 10),
      data_vencimento: (new Date()).toISOString().slice(0, 10),
      data_pagamento: (new Date()).toISOString().slice(0, 10),
      categoria_id: '',
      conta_id: '',
      pessoa_id: '',
      observacao: '',
    }) {
    this.id = id
    this.valor_pagamento = valor_pagamento
    this.status_pagamento = status_pagamento
    this.data_referencia = data_referencia
    this.data_vencimento = data_vencimento
    this.data_pagamento = data_pagamento
    this.categoria_id = categoria_id
    this.conta_id = conta_id
    this.pessoa_id = pessoa_id
    this.observacao = observacao
  }
}

export function createFluxoCaixa(data) {
  return Object.freeze(new FluxoCaixa(data))
}
