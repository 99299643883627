export default [
  {
    icon: 'mdi-monitor-dashboard',
    title: 'Início',
    to: '/gestor/dashboard',
    group: '',
  },
  {
    icon: 'mdi-notebook',
    title: 'Lançamentos',
    group: '/gestor',
    children: [
      {
        icon: 'mdi-notebook-edit',
        title: 'Lançamento do Faturamento',
        to: 'lancamentoFaturamento',
      },
      {
        icon: 'mdi-notebook-plus',
        title: 'Lançamento Avulso',
        to: 'lancamentoAvulso',
      },
    ],
  },
  {
    icon: 'mdi-calculator',
    title: 'Cálculo',
    group: '/gestor',
    children: [
      {
        icon: 'mdi-calculator-variant-outline',
        title: 'Cálculo Mensal',
        to: 'calculos',
      },
      {
        icon: 'mdi-file-outline',
        title: 'Extrato Mensal Fornecedor',
        to: 'extratoFornecedor',
      },
      {
        icon: 'mdi-alpha-a-box-outline',
        title: 'Extrato Mensal Associado',
        to: 'extratoAssociado',
      },
      {
        icon: 'mdi-table-cog',
        title: 'Eventos de Cálculo',
        to: 'eventos',
      },
    ],
  },
  {
    icon: 'mdi-clipboard-flow',
    title: 'Financeiro',
    group: '/gestor',
    children: [
      {
        icon: 'mdi-clipboard-search',
        title: 'Fluxo de Caixa',
        to: 'fluxoCaixa',
      },
      {
        icon: 'mdi-notebook-edit',
        title: 'Movimentações',
        to: 'fluxoCaixaMovimentacoes',
      },
      {
        icon: 'mdi-clipboard-check-multiple',
        title: 'Categorias',
        to: 'fluxoCaixaCategorias',
      },
      {
        icon: 'mdi-bank',
        title: 'Contas Bancárias',
        to: 'fluxoCaixaContas',
      },
    ],
  },
  {
    icon: 'mdi-account-details',
    title: 'Pessoas',
    group: '/gestor',
    children: [
      {
        icon: 'mdi-account-group',
        title: 'Associados',
        to: 'associados',
      },
      {
        icon: 'mdi-account-supervisor',
        title: 'Fornecedores',
        to: 'fornecedores',
      },
      {
        icon: 'mdi-account-hard-hat',
        title: 'Funcionários',
        to: 'funcionarios',
      },
    ],
  },
]
