const router = function (permissions) {
  return ({
    path: 'fluxoCaixaCategorias',
    component: () => import('@/views/FluxoCaixaCategorias/FluxoCaixaCategoriasModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/FluxoCaixaCategorias/FluxoCaixaCategoriasList.vue'),
        meta: { permissions, title: 'Categorias Financeiras' },
      },
      {
        path: 'cadastrar',
        component: () => import('@/views/FluxoCaixaCategorias/FluxoCaixaCategoriasProfile.vue'),
        meta: { permissions, title: 'Cadastrando Categoria' },
      },
      {
        path: 'alterar',
        component: () => import('@/views/FluxoCaixaCategorias/FluxoCaixaCategoriasProfile.vue'),
        meta: { permissions, title: 'Editando Categoria' },
      },
    ],
  })
}

export default {
  router,
}
