import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const BOOTSTRAP = 'BOOTSTRAP'
export const LOAD_DASHBOARD = 'LOAD_DASHBOARD'
export const NEW_RECEITA = 'NEW_RECEITA'
export const NEW_DESPESA = 'NEW_DESPESA'
export const EDITAR_RECEITA = 'EDITAR_RECEITA'
export const EDITAR_DESPESA = 'EDITAR_DESPESA'
export const RESET_STATE = 'RESET_STATE'
export const PERIODO = 'PERIODO'

const getDefaultState = () => {
  return {
    loading: true,
    data_i: 'from',
    dashboard: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE](state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE](state) {
    Object.assign(state, getDefaultState())
  },
  [PERIODO](state, { data_i }) {
    state.data_i = data_i
  },
}

const actions = {
  async [LOAD_DASHBOARD]({ commit, state }) {
    await api.listEntidade('fluxo_caixa/dashboard/' + state.data_i).then(
      dashboard => {
        commit(SET_STATE, { dashboard })
      },
    )
  },
  [NEW_RECEITA]({ dispatch }) {
    dispatch('form/modalLateral/BOOTSTRAP_PROFILE', { id: null, type: 'receita', route: 'fluxoCaixa' }, { root: true })
  },
  [EDITAR_RECEITA]({ dispatch }, fluxoCaixa) {
    dispatch('form/modalLateral/BOOTSTRAP_PROFILE', { id: fluxoCaixa.id, type: 'receita', route: 'fluxoCaixa' }, { root: true })
  },
  [NEW_DESPESA]({ dispatch }) {
    dispatch('form/modalLateral/BOOTSTRAP_PROFILE', { id: null, type: 'despesa', route: 'fluxoCaixa' }, { root: true })
  },
  [EDITAR_DESPESA]({ dispatch }, fluxoCaixa) {
    dispatch('form/modalLateral/BOOTSTRAP_PROFILE', { id: fluxoCaixa.id, type: 'despesa', route: 'fluxoCaixa' }, { root: true })
  },
  async [BOOTSTRAP]({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_DASHBOARD)
    commit(SET_STATE, { loading: false })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
