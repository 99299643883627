import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { Conta } from '@/models/Conta'
import Swal from 'sweetalert2'
import rules from '@/utils/formRules'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'
export const CLOSE_CONTA = 'CLOSE_CONTA'
export const LOAD_CONTAS = 'LOAD_CONTAS'

const getDefaultState = () => {
  return {
    loading: true,
    isEditing: false,
    isConta: false,
    conta_dialog: false,
    loadedContas: [],
    rules,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [CLOSE_CONTA] (state) {
    state.conta_dialog = false
    state.form = new Conta()
  },
}

const actions = {
  async [LOAD_CONTAS] ({ commit }) {
    await api.listEntidade('conta').then(response => {
      const conta = response
      conta.sort((A, B) => {
        const a = A.instituicao_financeira.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')

        const b = B.instituicao_financeira.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')
        return a < b ? -1 : a > b ? 1 : 0
      })

      commit(SET_STATE, { loadedContas: conta })
    })
  },
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id }) {
    commit(RESET_STATE)
    commit(SET_STATE, { loading: true, isEditing: false, conta_dialog: true })
    dispatch('form/BOOTSTRAP_FORM', { model: new Conta() }, { root: true })

    if (id) {
      commit(SET_STATE, { isEditing: true })
      api.getEntidade('conta', id).then(response => {
        dispatch('form/BOOTSTRAP_FORM', { model: new Conta(response.data[0]) }, { root: true })
        commit(SET_STATE, { loading: false })
      })
    } else {
      commit(SET_STATE, { loading: false })
    }
  },
  async [SUBMIT] ({ commit, dispatch, state, rootState }, { router, user }) {
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        const form = { ...rootState.form.data };

        (() => {
          return state.isEditing
            ? api.salvarEntidade(form, 'conta', form.id)
            : api.cadastrarEntidade(form, 'conta')
        })().then(
          data => {
            Swal.fire({
              title: state.isEditing
                ? 'Conta atualizado com sucesso!'
                : 'Conta cadastrado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            commit(SET_STATE, { conta_dialog: false })
            dispatch('conta/BOOTSTRAP', null, { root: true })
          },
          error => {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: error.errors ? error.errors : error.message,
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
