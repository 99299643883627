import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { Transferencia } from '@/models/Transferencia'
import Swal from 'sweetalert2'
import rules from '@/utils/formRules'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'
export const CLOSE_TRANSFERENCIA = 'CLOSE_TRANSFERENCIA'
export const LOAD_TRANSFERENCIAS = 'LOAD_TRANSFERENCIAS'
export const LOAD_CONTAS = 'LOAD_CONTAS'

const getDefaultState = () => {
  return {
    loading: true,
    isEditing: false,
    transferencia_dialog: false,
    loadedTrasnferencia: [],
    loadedContas: [],
    rules,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [CLOSE_TRANSFERENCIA] (state) {
    state.transferencia_dialog = false
    state.form = new Transferencia()
  },
}

const actions = {
  async [LOAD_TRANSFERENCIAS] ({ commit, state }) {
    // await api.listEntidade('fornecedor/mini').then(response => {
    //   let contas
    //   if (id) {
    //     contas = response
    //   } else {
    //     contas = response.filter(categ => categ.status === 1)
    //   }
    //   contas.sort((A, B) => {
    //     const a = A.razao_social.toLowerCase()
    //       .toLowerCase()
    //       .replace(/[àáâãäå]/, 'a')
    //       .replace(/[èéêë]/, 'e')
    //       .replace(/[ìíîï]/, 'i')
    //       .replace(/[òóôõö]/, 'o')
    //       .replace(/[ùúûü]/, 'u')
    //       .replace(/[ç]/, 'c')
    //       .replace(/[^a-z0-9]/gi, '')

    //     const b = B.razao_social.toLowerCase()
    //       .toLowerCase()
    //       .replace(/[àáâãäå]/, 'a')
    //       .replace(/[èéêë]/, 'e')
    //       .replace(/[ìíîï]/, 'i')
    //       .replace(/[òóôõö]/, 'o')
    //       .replace(/[ùúûü]/, 'u')
    //       .replace(/[ç]/, 'c')
    //       .replace(/[^a-z0-9]/gi, '')
    //     return a < b ? -1 : a > b ? 1 : 0
    //   })
    //   commit(SET_STATE, { loadedCategoria: categoria })
    // })
  },
  async [LOAD_CONTAS] ({ commit }) {
    await api.listEntidade('conta').then(response => {
      const conta = response
      conta.sort((A, B) => {
        const a = A.instituicao_financeira.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')

        const b = B.instituicao_financeira.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')
        return a < b ? -1 : a > b ? 1 : 0
      })

      // associado.forEach(element => {
      //   element.razao_social = '(' + element.codigo + ')  ' + element.razao_social
      // })
      commit(SET_STATE, { loadedContas: conta })
    })
  },
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id }) {
    commit(RESET_STATE)
    commit(SET_STATE, { loading: true, isEditing: false, transferencia_dialog: true })
    dispatch('form/BOOTSTRAP_FORM', { model: new Transferencia() }, { root: true })
    // await dispatch(LOAD_FORNECEDOR, { id })
    // await dispatch(LOAD_TRANSFERENCIAS)
    await dispatch(LOAD_CONTAS)

    if (id) {
      commit(SET_STATE, { isEditing: true })
      api.getEntidade('transferencia', id).then(response => {
        dispatch('form/BOOTSTRAP_FORM', { model: new Transferencia(response.data[0]) }, { root: true })
        commit(SET_STATE, { loading: false })
      })
    } else {
      commit(SET_STATE, { loading: false })
    }
  },
  async [SUBMIT] ({ commit, dispatch, state, rootState }, { router, user }) {
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        const form = { ...rootState.form.data };

        (() => {
          return state.isEditing
            ? api.salvarEntidade(form, 'transferencia', form.id)
            : api.cadastrarEntidade(form, 'transferencia')
        })().then(
          data => {
            Swal.fire({
              title: state.isEditing
                ? 'Transferência atualizada com sucesso!'
                : 'Transferência cadastrada com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            commit(SET_STATE, { transferencia_dialog: false })
            dispatch('transferencia/BOOTSTRAP', null, { root: true })
          },
          error => {
            Swal.fire({
              icon: 'error',
              title: 'Atenção',
              html: error.errors ? error.errors : error.message,
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
