import profile from './profile'
import keys from './keys'
import unidadesnegocio from './unidadesnegocio'
import gestores from './gestores'
import fornecedores from './fornecedores'
import funcionarios from './funcionarios'
import associados from './associados'
import administradores from './administradores'
import lancamentoFaturas from './lancamentoFaturas'
import lancamentoAvulsos from './lancamentoAvulsos'
import calculos from './calculos'
import extratoFornecedor from './extratoFornecedor'
import extratoAssociado from './extratoAssociado'
import fluxoCaixa from './fluxoCaixa'
import fluxoCaixaContas from './fluxoCaixaContas'
import fluxoCaixaCategoria from './fluxoCaixaCategoria'
import fluxoCaixaMovimentacoes from './fluxoCaixaMovimentacoes'
import fluxoCaixaContaExtrato from './fluxoCaixaContaExtrato'
import regionais from './regionais'
import eventos from './eventos'

const permissions = [
  {
    role: 'gestor',
    access: false,
    redirect: 'gestor',
  },
  {
    role: 'guest',
    access: false,
    redirect: 'login',
  },
]

export default {
  /* ACESSO ADMINISTRADOR */
  name: 'admin',
  path: '/admin',
  redirect: '/admin/dashboard',
  component: () => import('@/views/Index'),
  meta: { permissions },
  children: [
    {
      path: 'dashboard',
      component: () => import('@/views/dashboard/DashboardAdmin'),
      meta: { permissions, title: 'Início' },
    },
    {
      path: 'acessos',
      component: () => import('@/views/User/ControleAcesso.vue'),
      meta: { permissions, title: 'Controle de Acesso' },
    },
    lancamentoFaturas.router(permissions),
    unidadesnegocio.router(permissions),
    administradores.router(permissions),
    gestores.router(permissions),
    fornecedores.router(permissions),
    funcionarios.router(permissions),
    associados.router(permissions),
    regionais.router(permissions),
    profile.router(permissions),
    eventos.router(permissions),
    calculos.router(permissions),
    extratoFornecedor.router(permissions),
    extratoAssociado.router(permissions),
    fluxoCaixa.router(permissions),
    fluxoCaixaCategoria.router(permissions),
    fluxoCaixaMovimentacoes.router(permissions),
    fluxoCaixaContas.router(permissions),
    fluxoCaixaContaExtrato.router(permissions),
    keys.router(permissions),
    lancamentoAvulsos.router(permissions),
  ],
}
