import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { LancamentoFaturas } from '@/models/LancamentoFaturas'
import Swal from 'sweetalert2'
import rules from '@/utils/formRules'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const LOAD_ROLES = 'LOAD_ROLES'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'
export const CLOSE = 'CLOSE'
export const LOAD_FORNECEDOR = 'LOAD_FORNECEDOR'
export const LOAD_ASSOCIADO = 'LOAD_ASSOCIADO'
export const LOAD_EVENTO = 'LOAD_EVENTO'

const getDefaultState = () => {
  return {
    loadingFatura: true,
    isEditing: false,
    dialog: false,
    loadedFornecedor: [],
    loadedAssociado: [],
    loadedEvento: [],
    rules,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [CLOSE] (state) {
    state.dialog = false
    state.form = new LancamentoFaturas()
  },
}

const actions = {
  async [LOAD_FORNECEDOR] ({ commit }, { id }) {
    await api.listEntidade('fornecedor/mini').then(response => {
      let fornecedor
      if (id) {
        fornecedor = response
      } else {
        fornecedor = response.filter(fornec => fornec.status === 1)
      }
      fornecedor = fornecedor.map(({ cpf, cnpj, razao_social, nome, ...fornecedor }) => ({
        ...fornecedor,
        cpf_cnpj: cpf || cnpj,
        nome_razao_social: nome || razao_social,
      }))
      fornecedor.sort((A, B) => {
        const a = A.nome_razao_social.toLowerCase()
          .toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')

        const b = B.nome_razao_social.toLowerCase()
          .toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')
        return a < b ? -1 : a > b ? 1 : 0
      })
      commit(SET_STATE, { loadedFornecedor: fornecedor })
    })
  },
  async [LOAD_ASSOCIADO] ({ commit }) {
    await api.listEntidade('associado/mini').then(response => {
      let associado = response.filter(assoc => assoc.status === 1)
      associado = associado.map(({ cpf, cnpj, razao_social, nome, ...associado }) => ({
        ...associado,
        cpf_cnpj: cpf || cnpj,
        nome_razao_social: nome || razao_social,
      }))
      associado.sort((A, B) => {
        const a = A.nome_razao_social.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')

        const b = B.nome_razao_social.toLowerCase()
          .replace(/[àáâãäå]/, 'a')
          .replace(/[èéêë]/, 'e')
          .replace(/[ìíîï]/, 'i')
          .replace(/[òóôõö]/, 'o')
          .replace(/[ùúûü]/, 'u')
          .replace(/[ç]/, 'c')
          .replace(/[^a-z0-9]/gi, '')
        return a < b ? -1 : a > b ? 1 : 0
      })
      associado.forEach(element => {
        element.nome_razao_social = '(' + element.codigo + ')  ' + element.nome_razao_social
      })
      commit(SET_STATE, { loadedAssociado: associado })
    })
  },
  async [LOAD_EVENTO] ({ commit }) {
    await api.listEntidade('evento').then(response => {
      const eventos = response.filter(evento => evento.status === 1 && evento.classe === 'S' && evento.tipo_lancamento === 1)
      eventos.sort((a, b) => parseFloat(a.codigo) - parseFloat(b.codigo))
      commit(SET_STATE, { loadedEvento: eventos })
    })
  },
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id }) {
    commit(RESET_STATE)
    commit(SET_STATE, { loadingFatura: true, isEditing: false, dialog: true })
    dispatch('form/BOOTSTRAP_FORM', { model: new LancamentoFaturas() }, { root: true })
    await dispatch(LOAD_FORNECEDOR, { id })
    await dispatch(LOAD_ASSOCIADO)
    await dispatch(LOAD_EVENTO)
    if (id) {
      commit(SET_STATE, { isEditing: true })
      api.getEntidade('lancamentoFaturamento', id).then(response => {
        dispatch('form/BOOTSTRAP_FORM', { model: new LancamentoFaturas(response.data[0]) }, { root: true })
        commit(SET_STATE, { loadingFatura: false })
      })
    } else {
      commit(SET_STATE, { loadingFatura: false })
    }
  },
  async [SUBMIT] ({ commit, dispatch, state, rootState }, { router, user }) {
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        const form = { ...rootState.form.data }
        form.data_referencia = form.data_referencia.substring(0, 7) + '-01'
        const itens = form.lancamentos.map(row => {
          return {
            associado_id: row.associado.id,
            valor_relatorio: row.valor_relatorio,
            valor_xml: row.valor_xml,
            valor_diferenca: row.valor_diferenca,
            valor_devolucao: row.valor_devolucao,
            evento_id: row.evento?.id,
            observacao: row.observacao,
          }
        })
        form.itens = itens;
        (() => {
          return state.isEditing
            ? api.salvarEntidade(form, 'lancamentoFaturamento', form.id)
            : api.cadastrarEntidade(form, 'lancamentoFaturamento')
        })().then(
          data => {
            Swal.fire({
              title: state.isEditing
                ? 'Lançamento atualizado com sucesso!'
                : 'Lançamento cadastrado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            commit(SET_STATE, { dialog: false })
            dispatch('lancamentoFaturas/BOOTSTRAP', null, { root: true })
          },
          error => {
            Swal.fire({
              icon: 'error',
              title: 'Atenção',
              html: error.errors ? error.errors : error.message,
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
